import React from 'react';

import {Grid} from '@mui/material';

import styles from './Team.module.scss';

const members = [
    {
        id: 'anna_mal',
        img: '/img/team/anna.jpg',
        name: 'Анна Мальцева',
        desc: 'Инструктор тренажерного зала и групповых программ',
        info: 'Мальцева Анна Александровна \n' +
            '\n' +
            'Сертифицированный инструктор групповых программ\n' +
            'Стаж работы 4 года\n' +
            'Стаж собственных тренировок 13 лет\n' +
            '\n' +
            '-курс "Инструктор ГП" СФК "Фитнес Сити"\n' +
            '- семинар "Стретчинг в группе"\n' +
            '- семинар "тренировки по протоколу Табата"\n' +
            '- базовый курс "Спортивная нутрициология"\n' +
            '- семинар "аквааэробика"\n' +
            '- семинар П.Фатыхова " Силовой тренинг в формате групповых программ"\n' +
            '- семинар коррекция осанки',
    },
    {
        id: 'irina_naz',
        img: '/img/team/irina.jpg',
        name: 'Ирина Назарова',
        desc: 'Инструктор тренажерного зала и групповых программ <br/>Стаж работы более 10 лет',
        info: 'Назарова Ирина \n' +
            'Владимировна \n' +
            '\n' +
            'Персональный тренер\n' +
            'Инструктор тренажерного зала \n' +
            'Инструктор групповых программ \n' +
            'Нутрициолог \n' +
            'Стаж работы более 10 лет\n' +
            '\n' +
            'Ассоциация профессионалов фитнеса (FPA), персональный тренер, инструктор тренажерного зала\n' +
            '• Федерация фитнес-аэробики России, инструктор групповых программ\n' +
            '• Колледж Вейдера, нутрициолог\n' +
            '• Семинар А.Василенко "Аэробика среднего и высокого уровня сложности" (танцевальная аэробика)\n' +
            '• Семинар А. Василенко "Функциональный тренинг в формате групповых программ "\n' +
            '• Семинар Д.Виноградова "Миофасциальный релиз в формате персональных тренировок"\n' +
            '• Курс FPA "Спортивный нутрициолог "\n' +
            '• Семинар ФФАР "Силовой тренинг"',
    },
    {
        id: 'daria_pro',
        img: '/img/team/daria_pro.jpg',
        name: 'Дарья Прокопова',
        desc: 'Инструктор тренажерного зала <br/>Персональный тренер',
        info: 'Прокопова Дарья  Сергеевна\n' +
            '\n' +
            'сертифицированный персональный тренер, инструктор тренажерного зала\n' +
            'Стаж работы 1 год\n' +
            'Стаж собственных тренировок 3 года\n' +
            '\n' +
            '-колледж фитнеса и бодибилдинга им. Б.Вейдера по программе "Инструктор фитнеса и бодибилдинга"\n' +
            '-курс вебинаров «Тазовое дно» Теоретическая и практическая части',
    },
    {
        id: 'alexander_mer',
        img: '/img/team/alexander.jpg',
        name: 'Александр Меркулин',
        desc: 'Инструктор тренажерного зала <br/>Мастер спорта по пауэрлифтингу',
        info: 'Меркулин Александр Сергеевич\n' +
            '\n' +
            'Сертифицированный персональный тренер, инструктор тренажерного зала, массажист\n' +
            'Стаж работы 8 лет\n' +
            'Стаж собственных тренировок 14 лет\n' +
            '\n' +
            'КМС по тхэквондо, МС по пауэрлифтингу, 1 разряд по гиревому спорту\n' +
            '\n' +
            '- Образование высшее БелГУ, факультет физической культуры, магистратура по направлению "Адаптивная физическая культура"\n' +
            '- повышение квалификации ФГАОУ ВО "БелГУ" по теории и методике атлетизма',
    },
    {
        id: 'daria_leb',
        img: '/img/team/daria_leb.jpg',
        name: 'Дарья Лебедева',
        desc: 'Тренер групповых программ <br/>Специалист по питанию с медицинским образованием',
        info: 'Лебедева Дарья Сергеевна\n' +
            '\n' +
            'Инструктор групповых программ \n' +
            'Тренер танцевальных направлений (twerk, классическая и эстрадная хореография) \n' +
            'Стаж работы более 4х лет\n' +
            'стаж собственных тренировок 17 лет\n' +
            '\n' +
            '-Специлист по питанию с высшим медицинским образованиема (курс «Медицинская эндокринология и диетология» НИУ БелГУ)\n' +
            '-Курс «Миофасциальный релиз с элементами стретчинга» (онлайн-школа Анатомия)\n' +
            '-Курс «Коррекция нарушений опорно-двигательного аппарата» (онлайн-школа Анатомия)',
    },
    /*{
        id: 'andrey_kvadrat',
        img: '',
        name: 'Андрей Квадрат',
        desc: 'Персональный тренер <br/>Мастер спорта по пауэрлифтингу',
    },*/
    {
        id: 'maksim_besedin',
        img: '/img/team/maksim.jpg',
        name: 'Максим Беседин',
        desc: 'Инструктор тренажерного зала <br/>Выступающий спортсмен',
        info: '',
    },
];

type Props = {
    min?: boolean;
    visibleIds?: string[];
};

export default function Team({min = false, visibleIds}: Props) {

    const visibleMembers = members.filter((member) => {
        if (visibleIds) {
            return visibleIds.includes(member.id);
        }
        return true;
    });

    return (
        <div className={styles.teamList}>
            <Grid container spacing={2} justifyContent="center">
                {visibleMembers.map(member => (
                    <Grid className={styles.gridItem} key={member.name} item md={4} sm={12}>
                        <div className={styles.member}>
                            <div className={styles.memberCircle}>
                                {member.img && (
                                    <img className={styles.memberImg} src={member.img} alt={member.name}/>
                                )}
                            </div>
                            <div className={styles.memberInfo}>
                                <div className={styles.memberName}>{member.name}</div>
                                <div
                                    className={styles.memberDesc}
                                    dangerouslySetInnerHTML={{__html: member.desc}}
                                />
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
