import React, {useEffect} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Route, Router, Switch, useLocation} from 'react-router-dom';

import {createTheme, ThemeProvider} from '@mui/material';

import MainLayout from './layouts/MainLayout';
import GroupsPage from './pages/GroupsPage/GroupsPage';
import GymPage from './pages/GymPage/GymPage';
import MainPage from './pages/MainPage/MainPage';
import MassagePage from './pages/MassagePage/MassagePage';
import MiniGroupsPage from './pages/MiniGroupsPage/MiniGroupsPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import PersonalTrainingPage from './pages/PersonalTrainingPage/PersonalTrainingPage';
import PricePage from './pages/PricePage/PricePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ServicesPage from './pages/ServicesPage/ServicesPage';

import 'swiper/swiper-bundle.min.css';
import SplitPage from './pages/SplitPage/SplitPage';
import TeamPage from './pages/TeamPage/TeamPage';
import AppContextProvider from './system/context/AppContextProvider';
import {history} from './system/navigation/navigationUtils';
import ConsentPersonalPage from './pages/ConsentPersonalPage/ConsentPersonalPage';

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        primary: {
            main: '#f9e91a',
        },
        secondary: {
            main: '#000000',
        },
    },
    typography: {
        fontFamily: [
            'Inter',
            'Roboto',
            'sans-serif',
        ].join(','),
    },
});

const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};

export default function App() {

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <AppContextProvider>
                    <Router history={history}>
                        <ScrollToTop/>
                        <MainLayout>
                            <Switch>
                                <Route path="/" exact>
                                    <MainPage/>
                                </Route>
                                <Route path="/gym">
                                    <GymPage/>
                                </Route>
                                <Route path="/personal-training">
                                    <PersonalTrainingPage/>
                                </Route>
                                <Route path="/split">
                                    <SplitPage/>
                                </Route>
                                <Route path="/mini-groups">
                                    <MiniGroupsPage/>
                                </Route>
                                <Route path="/groups">
                                    <GroupsPage/>
                                </Route>
                                <Route path="/massage">
                                    <MassagePage/>
                                </Route>
                                <Route path="/services">
                                    <ServicesPage/>
                                </Route>
                                <Route path="/team">
                                    <TeamPage/>
                                </Route>
                                <Route path="/price">
                                    <PricePage/>
                                </Route>
                                {/*<Route path="/schedule">
                                    <SchedulePage/>
                                </Route>*/}
                                <Route path="/consent-personal">
                                    <ConsentPersonalPage/>
                                </Route>
                                <Route path="/privacy-policy">
                                    <PrivacyPolicyPage/>
                                </Route>
                                <Route path="*">
                                    <NotFoundPage/>
                                </Route>
                            </Switch>
                        </MainLayout>
                    </Router>
                </AppContextProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}
