import React from 'react';
import {Link} from 'react-router-dom';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import {Button} from '@mui/material';

import telegramLogoBlue from '../../../assets/icons/telegram_blue.svg';
import vkLogoBlue from '../../../assets/icons/vk_blue.svg';
import logoPng from '../../../assets/img/logo_min.png';
import {useOpenContactsModal} from '../../../hooks/utilsHooks';
import {history} from '../../../system/navigation/navigationUtils';

import styles from './HeaderDesktop.module.scss';

interface Props {
}

export default function HeaderDesktop({}: Props) {

    const openContactsModal = useOpenContactsModal();

    return (
        <header className={styles.header}>
            {/*<div className={styles.headerTop}>
                <div className="container">
                    <div className={styles.headerInner}>
                    </div>
                </div>
            </div>*/}
            <div className={styles.headerMain}>
                <div className="container">
                    <div className={styles.headerInner}>
                        <div className={styles.logo} onClick={() => history.push('/')}>
                            <img className={styles.logoImg} src={logoPng} alt="Titan Gym"/>
                        </div>
                        <nav className={styles.nav}>
                            <div className={styles.navItem}>
                                <Link className={styles.navLink} to="/gym">Наш зал</Link>
                            </div>
                            <div className={styles.navItem}>
                                <Link className={styles.navLink} to="/services">Услуги</Link>
                            </div>
                            <div className={styles.navItem}>
                                <Link className={styles.navLink} to="/team">Команда</Link>
                            </div>
                            <div className={styles.navItem}>
                                <Link className={styles.navLink} to="/price">Цены</Link>
                            </div>
                            {/*<div className={styles.navItem}>
                                <Link className={styles.navLink} to="/groups#schedule">Расписание</Link>
                            </div>*/}
                            <div className={styles.navItem}>
                                <div
                                    className={styles.navItemBtn}
                                    onClick={() => openContactsModal({
                                        pageName: 'Главная',
                                        msgDsc: 'Хочет записаться в зал, надо узнать подробнее',
                                    })}
                                >
                                    Записаться
                                </div>
                            </div>
                            <div className={styles.navSocials}>
                                <a
                                    className={styles.navSocialLink}
                                    href="https://vk.com/titangym_belgorod"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img className={styles.navSocialIcon} src={vkLogoBlue} alt=""/>
                                </a>
                                <a
                                    className={styles.navSocialLink}
                                    style={{marginLeft: 12}}
                                    href="https://t.me/titangym_belgorod"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img className={styles.navSocialIcon} src={telegramLogoBlue} alt=""/>
                                </a>
                            </div>
                        </nav>
                        <div className={styles.contacts}>
                            <div className={styles.address}>
                                <LocationOnIcon/>
                                <span style={{marginLeft: 8}}>г. Белгород, ул. Щорса 45а</span>
                            </div>
                            <div className={styles.phone}>
                                <PhoneIcon/>
                                <a className={styles.phoneLink} href="tel:+79606332007">+7 (960) 633-20-07</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
