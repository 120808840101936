import React from 'react';

import MultiProvider from '../MultiProviders';
import { ModalContextProvider } from './ModalContext';

type Props = {
    children: any;
};

export default function AppContextProvider({children}: Props) {
    return (
        <MultiProvider
            providers={[
                <ModalContextProvider/>,
            ]}
        >
            {children}
        </MultiProvider>
    );
}
