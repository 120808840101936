import * as React from 'react';
import {useState} from 'react';
import {isMobile} from 'react-device-detect';

import CloseIcon from '@mui/icons-material/Close';
import {Backdrop, IconButton, ImageList, ImageListItem} from '@mui/material';
import {EffectFade, Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {Swiper as SwiperClass} from 'swiper/types';

import styles from './Gallery.module.scss';

import './Gallery.css';

type Props = {
    images: any[];
    cols?: number;
    galleryClass?: string;
};

export default function Gallery({images, cols = 3, galleryClass = ''}: Props) {

    const [swiper, setSwiper] = useState<SwiperClass>();
    const [selectedImg, setSelectedImg] = useState<null | number>(null);

    const isOpen = selectedImg !== null;

    const handleSelectImg = (index: number) => {
        swiper?.slideTo(index + 1);
        setSelectedImg(index);
    };

    return (
        <div className={`gallery ${galleryClass}`}>
            <Backdrop
                sx={{color: 'white', background: 'rgba(0, 0, 0, 0.9)', zIndex: 100}}
                open={isOpen}
                /*onClick={() => setSelectedImg(null)}*/
            >
                <div className={styles.fullscreen}>
                    <div className={styles.close} onClick={() => setSelectedImg(null)}>
                        <IconButton style={{fontSize: 48}} component="span">
                            <CloseIcon color="primary" fontSize="inherit"/>
                        </IconButton>
                    </div>
                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        grabCursor={true}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        effect={isOpen ? 'fade' : 'fade'}
                        navigation={!isMobile}
                        pagination={isMobile ? {
                            dynamicBullets: true,
                        } : false}
                        modules={[EffectFade, Navigation, Pagination]}
                        onSlideChange={() => {
                        }}
                        onSwiper={(swiper) => setSwiper(swiper)}
                    >
                        {images.map((item) => (
                            <SwiperSlide key={item.img}>
                                <img className="slide-img" src={item.img} alt=""/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </Backdrop>
            <ImageList style={{margin: 0}} cols={cols}>
                {images.map((item, index) => (
                    <ImageListItem key={item.img}>
                        <img
                            className={styles.imgList}
                            src={`${item.img}`}
                            srcSet={`${item.img}`}
                            alt={item.title}
                            onClick={() => handleSelectImg(index)}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}
