import React, {useEffect, useRef} from 'react';
import {isMobile} from 'react-device-detect';
import {Helmet} from 'react-helmet';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, Grid, IconButton} from '@mui/material';

import Reviews from '../../components/blocks/Reviews/Reviews';
import Schedule from '../../components/blocks/Schedule/Schedule';
import ScheduleDesktop from '../../components/blocks/ScheduleDesktop/ScheduleDesktop';
import Services from '../../components/blocks/Services/Services';
import {useOpenContactsModal} from '../../hooks/utilsHooks';
import {history} from '../../system/navigation/navigationUtils';

import stylesAbout from '../../components/ui/About/UiAbout.module.scss';
import stylesPromo from '../../components/ui/Promo/UiPromo.module.scss';
import stylesService from '../../components/ui/Service/UiService.module.scss';

export default function GroupsPage() {

    const openContactsModal = useOpenContactsModal();

    const scheduleRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (window.location.href.includes('#schedule') && scheduleRef.current) {
            scheduleRef.current.scrollIntoView({
                // behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>Групповые тренировки и занятия в тренажерном зале Titan Gym Белгород</title>
            </Helmet>
            <section id="promo" className={stylesService.promo}>
                <div className={stylesPromo.wrapper}>
                    <div className="container">
                        <div className={stylesPromo.info}>
                            <div className={stylesPromo.backBtn} onClick={() => history.goBack()}>
                                <IconButton component="span">
                                    <ArrowBackIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <span>Назад</span>
                            </div>
                            <h1 className={stylesPromo.title}>Групповые<br/> программы</h1>
                            <div className={stylesPromo.line}/>
                            <p className={stylesPromo.desc}>
                                TITAN GYM – это сильная атмосфера и разнообразные тренировочные пространства, где каждый
                                найдет оборудование для достижения своих целей. Удобное разделение по зонам и
                                продуманное расположение обеспечивают максимальный комфорт. У нас нет проблем с
                                нехваткой тренажеров и дисков, даже в самое нагруженное время!
                            </p>
                            <Button
                                style={{fontWeight: 600}}
                                variant="contained"
                                onClick={() => openContactsModal({
                                    pageName: 'Групповые программы',
                                    msgDsc: 'Хочет записаться на бесплатную тренировку по групповым',
                                })}
                            >
                                Записаться на бесплатную тренировку
                            </Button>
                        </div>
                    </div>
                    <div
                        className={stylesPromo.image}
                        style={{backgroundImage: 'url("/img/services/groups.jpg")'}}
                    />
                </div>
            </section>
            <section id="schedule" className={stylesService.schedule} ref={scheduleRef}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h3 className={`section-title ${stylesService.scheduleTitle}`}>Расписание</h3>
                    </div>
                    <Schedule colorText="black" background="white"/>
                    <ScheduleDesktop colorText="gray" background="white"/>
                </div>
            </section>
            <section id="about" className={stylesService.about} style={{display: 'none'}}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Программы</h4>
                    </div>
                    <div className={stylesAbout.wrapper}>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary}>
                                    <div className={stylesAbout.headline}>Стретчинг</div>
                                    <div className={stylesAbout.desc}>
                                        Тренировка направлена на расслабление и улучшение гибкости мышц и связок. Целью
                                        тренировки является повышение мобильности суставов, что очень важно в любом
                                        возрасте.<br/>
                                        <br/>
                                        Регулярные тренировки помогают снизить риск травм и улучшить силовые
                                        показатели. Хотите сесть на шпагат? Болит спина от сидячей работы? Тогда Вам
                                        точно на тренировки по направлению стретчинг!
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary}>
                                    <div className={stylesAbout.headline}>Power Body</div>
                                    <div className={stylesAbout.desc}>
                                        Силовая тренировка , включающая в себя классическую аэробику, функциональные
                                        связки, силовые и кардио упражнения с различным оборудованием, заминка
                                        обязательно в формате стретч-блока.<br/>
                                        <br/>
                                        Целью выполнения упражнений является
                                        укрепление и развитие всех мышц тела, формирование рельефа, жиросжигание,
                                        укрепление и мобильность суставов.
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary}>
                                    <div className={stylesAbout.headline}>Fitness Mix</div>
                                    <div className={stylesAbout.desc}>
                                        Направление, которое сочетает в себе аэробную, силовую нагрузки, функциональные
                                        связки и заминку в формате стретчинга.<br/>
                                        <br/>
                                        Развивается силовая выносливость, создается и поддерживается мышечный рельеф и
                                        тонус, тренируется и улучшается работа дыхательной и сердечнососудистой систем.
                                        Происходит заметное улучшение осанки.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary} style={{backgroundColor: '#ff616f'}}>
                                    <div className={stylesAbout.headline}>Коррекция фигуры</div>
                                    <div className={stylesAbout.desc}>
                                        Cиловая тренировка, целью которой является, укрепление всех мышц
                                        тела, формирование гармоничных пропорций, рельефа, в том числе за счет
                                        жиросжигания, развитие мышечной силы и выносливости.<br/>
                                        <br/>
                                        Побочными эффектами занятий Power Body является общеукрепляющий эффект,
                                        оздоровление организма.
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary} style={{backgroundColor: '#ff616f'}}>
                                    <div className={stylesAbout.headline}>ABS+BUMS</div>
                                    <div className={stylesAbout.desc}>
                                        Направление, которое сочетает в себе аэробную, силовую нагрузки, функциональные
                                        связки и заминку в формате стретчинга.<br/>
                                        <br/>
                                        Развивается силовая выносливость, создается и поддерживается мышечный рельеф и
                                        тонус, тренируется и улучшается работа дыхательной и сердечнососудистой систем.
                                        Происходит заметное улучшение осанки.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary} style={{backgroundColor: '#38cd85'}}>
                                    <div className={stylesAbout.headline}>Ягодицы+</div>
                                    <div className={stylesAbout.desc}>
                                        Cиловая тренировка, целью которой является, укрепление всех мышц
                                        тела, формирование гармоничных пропорций, рельефа, в том числе за счет
                                        жиросжигания, развитие мышечной силы и выносливости.<br/>
                                        <br/>
                                        Побочными эффектами занятий Power Body является общеукрепляющий эффект,
                                        оздоровление организма.
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className={stylesAbout.lineCardContainer} container spacing={isMobile ? 0 : 3}>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div className={stylesAbout.cardPrimary} style={{backgroundColor: '#38cd85'}}>
                                    <div className={stylesAbout.headline}>ROUND TRAINING</div>
                                    <div className={stylesAbout.desc}>
                                        Направление, которое сочетает в себе аэробную, силовую нагрузки, функциональные
                                        связки и заминку в формате стретчинга.<br/>
                                        <br/>
                                        Развивается силовая выносливость, создается и поддерживается мышечный рельеф и
                                        тонус, тренируется и улучшается работа дыхательной и сердечнососудистой систем.
                                        Происходит заметное улучшение осанки.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" lg={6} sm={12}>
                                <div
                                    className={stylesAbout.image}
                                    style={{backgroundImage: 'url("/img/groups/anna2.jpg")'}}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section id="reviews" className={stylesService.reviews}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Отзывы</h4>
                    </div>
                    <Reviews/>
                </div>
            </section>
            {/*<div className="section-divider-wrap">
                <div className="section-divider-gray"/>
            </div>*/}
            <section id="services" className={stylesService.services}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Другие услуги</h4>
                    </div>
                    <Services min={true} visibleIds={['gym', 'personal-training', 'mini-groups']}/>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
