import React, {useState} from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import {Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemText} from '@mui/material';

import telegramLogoBlue from '../../../assets/icons/telegram_blue.svg';
import vkLogoBlue from '../../../assets/icons/vk_blue.svg';
import logoPng from '../../../assets/img/logo_min.png';
import {useOpenContactsModal} from '../../../hooks/utilsHooks';
import {history} from '../../../system/navigation/navigationUtils';

import styles from './MenuMobile.module.scss';

const nav = [
    {
        name: 'Главная',
        link: '/',
    },
    {
        name: 'Наш зал',
        link: '/gym',
    },
    {
        name: 'Услуги',
        link: '/services',
    },
    {
        name: 'Команда',
        link: '/team',
    },
    {
        name: 'Цены',
        link: '/price',
    },
    {
        name: 'Расписание',
        link: '/groups#schedule',
    },
];

interface Props {
}

export default function MenuMobile({}: Props) {

    const [isOpen, setOpen] = useState<boolean>(false);

    const openContactsModal = useOpenContactsModal();

    const toggleDrawer = () => {
        setOpen(!isOpen);
    };

    return (
        <header className={styles.header}>
            <div className={styles.headerInner}>
                <div className={styles.menuBtn} onClick={toggleDrawer}>
                    <IconButton component="span">
                        <MenuIcon color="secondary"/>
                    </IconButton>
                </div>
                <div className={styles.logo} onClick={() => history.push('/')}>
                    <img className={styles.logoImg} src={logoPng} alt="Titan Gym"/>
                </div>
                <a className={styles.callBtn} href="tel:+79606332007">
                    <IconButton component="span">
                        <PhoneIcon color="secondary"/>
                    </IconButton>
                </a>
            </div>
            <Drawer
                anchor="left"
                open={isOpen}
                onClose={() => setOpen(false)}
            >
                <Box
                    style={{width: 280, height: '100%', backgroundColor: '#f9e91a'}}
                    role="presentation"
                    onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}
                >
                    <List>
                        {nav.map((item) => (
                            <ListItem button key={item.name} onClick={() => history.push(item.link)}>
                                <ListItemText
                                    className={styles.navItemText}
                                    primary={item.name}
                                    primaryTypographyProps={{fontSize: 18, fontWeight: 500}}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <div>
                        <div className={styles.contacts}>
                            <div className={styles.address}>
                                <LocationOnIcon fontSize="medium"/>
                                <span style={{marginLeft: 8}}>г. Белгород, ул. Щорса 45а</span>
                            </div>
                            <div className={styles.phone}>
                                <PhoneIcon fontSize="medium"/>
                                <a className={styles.phoneLink} href="tel:+79606332007">+7 (960) 633-20-07</a>
                            </div>
                        </div>
                        <div className={styles.navSocials}>
                            <a
                                className={styles.navSocialLink}
                                style={{marginRight: 12}}
                                href="https://vk.com/titangym_belgorod"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className={styles.navSocialIcon} src={vkLogoBlue} alt=""/>
                            </a>
                            <a
                                className={styles.navSocialLink}
                                style={{marginLeft: 12}}
                                href="https://t.me/titangym_belgorod"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className={styles.navSocialIcon} src={telegramLogoBlue} alt=""/>
                            </a>
                        </div>
                        <div className={styles.buttonsWrap}>
                            <Button
                                className={styles.saleButton}
                                variant="contained"
                                size="medium"
                                color="secondary"
                                disableElevation
                                onClick={() => openContactsModal({
                                    pageName: 'Мобильное меню',
                                    msgDsc: 'Хочет записаться в зал, надо узнать подробнее',
                                })}
                            >
                                Бесплатная тренировка
                            </Button>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </header>
    );
}
