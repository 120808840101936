export const days = [
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    // 'Сб',
    // 'Вс',
];

export const events = [
    [
        /*{
            time: '10:00',
            name: 'FitnessMix',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },*/
        /*{
            time: '10:00',
            name: 'Fit Mix',
            desc: '',
            trainer: 'Юлия Самойленко',
            background: '#f9911a',
        },
        {
            time: '18:00',
            name: 'Пилатес',
            desc: '',
            trainer: 'Ирина Хайрулина',
            background: '#a361ff',
        },*/
        {
            time: '19:00',
            // name: 'Коррекция фигуры',
            name: 'ABS+BUMS Mix',
            desc: '',
            trainer: 'Дарья Лебедева',
            background: '#ff616f',
        },
        /*{
            time: '20:00',
            name: 'ABS+BUMS',
            desc: '',
            trainer: 'Дарья Лебедева',
            background: '#ff616f',
        },*/
    ],
    [
        /*{
            time: '10:00',
            name: 'Ягодицы+',
            desc: '',
            trainer: 'Ирина Назарова',
            background: '#38cd85',
        },*/
        /*{
            time: '17:30',
            name: 'Йога',
            desc: '',
            trainer: 'Ирина Серебрюхова',
            background: '#47a6e2',
        },*/
        /*{
            time: '19:00',
            name: 'POWER BODY',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },
        {
            time: '20:00',
            name: 'Стретчинг',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },*/
        {
            time: '19:00',
            name: 'Fitness Mix',
            desc: '',
            trainer: 'Кристина Друзева',
            background: '#a361ff',
        },
        {
            time: '20:00',
            name: 'Стретчинг',
            desc: '',
            trainer: 'Кристина Друзева',
            background: '#a361ff',
        },
    ],
    [
        /*{
            time: '10:00',
            name: 'FitnessMix',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },*/
        /*{
            time: '10:00',
            name: 'Fit Mix',
            desc: '',
            trainer: 'Юлия Самойленко',
            background: '#f9911a',
        },
        {
            time: '18:00',
            name: 'Пилатес',
            desc: '',
            trainer: 'Ирина Хайрулина',
            background: '#a361ff',
        },*/
        {
            time: '19:00',
            // name: 'Коррекция фигуры',
            name: 'ABS+BUMS Mix',
            desc: '',
            trainer: 'Дарья Лебедева',
            background: '#ff616f',
        },
        /*{
            time: '20:00',
            name: 'ABS+BUMS',
            desc: '',
            trainer: 'Дарья Лебедева',
            background: '#ff616f',
        },*/
    ],
    [
        /*{
            time: '10:00',
            name: 'ROUND TRAINING',
            desc: '',
            trainer: 'Ирина Назарова',
            background: '#38cd85',
        },*/
        /*{
            time: '17:30',
            name: 'Йога',
            desc: '',
            trainer: 'Ирина Серебрюхова',
            background: '#47a6e2',
        },*/
        /*{
            time: '19:00',
            name: 'POWER BODY',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },
        {
            time: '20:00',
            name: 'Стретчинг',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },*/
        {
            time: '19:00',
            name: 'Fitness Mix',
            desc: '',
            trainer: 'Кристина Друзева',
            background: '#a361ff',
        },
        {
            time: '20:00',
            name: 'Стретчинг',
            desc: '',
            trainer: 'Кристина Друзева',
            background: '#a361ff',
        },
    ],
    [
        /*{
            time: '10:00',
            name: 'FitnessMix',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },*/
        /*{
            time: '10:00',
            name: 'Fit Mix',
            desc: '',
            trainer: 'Юлия Самойленко',
            background: '#f9911a',
        },
        {
            time: '18:00',
            name: 'Пилатес',
            desc: '',
            trainer: 'Ирина Хайрулина',
            background: '#a361ff',
        },*/
        {
            time: '19:00',
            // name: 'Коррекция фигуры',
            name: 'ABS+BUMS Mix',
            desc: '',
            trainer: 'Дарья Лебедева',
            background: '#ff616f',
        },
        /*{
            time: '20:00',
            name: 'ABS+BUMS',
            desc: '',
            trainer: 'Дарья Лебедева',
            background: '#ff616f',
        },*/
    ],
    /*[
        {
           time: '10:30',
           name: 'ROUND INTENSIVE',
           desc: '',
           trainer: 'Ирина Назарова',
           background: '#38cd85',
        },*/
        /*{
            time: '12:00',
            name: 'POWER BODY',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        },
        {
            time: '13:00',
            name: 'Стретчинг',
            desc: '',
            trainer: 'Анна Мальцева',
            background: '#f9e91a',
        }
    ],*/
    // [],
];
