import React, {createContext, useContext, useState} from 'react';

export type TModalContext = {
    openedKey: string | null;
    openModal: (value: string) => void;
    closeModal: () => void;
    data: Record<string, any> | null;
    setData: (value: Record<string, any>) => void;
};

const ModalContext = createContext<TModalContext>({} as any);

export const useModalContext = () => useContext(ModalContext);

export function ModalContextProvider({children}: any) {
    const [openedKey, setOpenedKey] = useState<string | null>(null);
    const [data, setData] = useState<Record<string, any> | null>(null);

    const openModal = (key: string) => {
        setOpenedKey(key);
    };

    const closeModal = () => {
        setOpenedKey(null);
    };

    const value: TModalContext = {
        openedKey,
        openModal,
        closeModal,
        data,
        setData,
    };

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    );
}
