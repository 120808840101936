import React from 'react';
import {Link} from 'react-router-dom';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Grid, IconButton} from '@mui/material';

import styles from './Services.module.scss';

const services = [
    {
        id: 'gym',
        name: 'Тренажерный зал',
        link: '/gym',
        img: '/img/services/gym.jpg',
    },
    {
        id: 'personal-training',
        name: 'Персональные тренировки',
        link: '/personal-training',
        img: '/img/services/personal.jpg',
    },
    {
        id: 'split',
        name: 'Сплит тренировки',
        link: '/split',
        img: '/img/services/split.jpg',
    },
    {
        id: 'mini-groups',
        name: 'Мини-группы',
        link: '/mini-groups',
        img: '/img/services/mini-groups.jpg',
    },
    {
        id: 'groups',
        name: 'Групповые программы',
        link: '/groups',
        img: '/img/services/groups.jpg',
    },
    {
        id: 'massage',
        name: 'Массаж',
        link: '/massage',
        img: '/img/services/massage.jpg',
    },
];

type Props = {
    min?: boolean;
    visibleIds?: string[];
};

export default function Services({min = false, visibleIds}: Props) {

    const visibleServices = services.filter((service) => {
        if (visibleIds) {
            return visibleIds.includes(service.id);
        }
        return true;
    });

    return (
        <div className={styles.servicesList}>
            <Grid container spacing={3}>
                {visibleServices.map(service => (
                    <Grid key={service.id} item md={min ? 4 : 6} sm={12}>
                        <div className={styles.card}>
                            <Link className={styles.link} to={service.link}>
                                <img
                                    className={styles.img}
                                    src={service.img}
                                    alt={service.name}
                                />
                                <div className={styles.info}>
                                    <h3 className={styles.name}>{service.name}</h3>
                                    <IconButton component="span">
                                        <ArrowForwardIcon color="secondary" fontSize="large"/>
                                    </IconButton>
                                </div>
                            </Link>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
