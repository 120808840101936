import React from 'react';
import {Helmet} from 'react-helmet';

import Reviews from '../../components/blocks/Reviews/Reviews';
import Services from '../../components/blocks/Services/Services';

import styles from './ServicesPage.module.scss';

export default function ServicesPage() {
    return (
        <div>
            <Helmet>
                <title>Услуги в тренажерном зале Titan Gym Белгород</title>
            </Helmet>
            <section id="services" className={styles.services}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Услуги</h4>
                    </div>
                    <Services/>
                </div>
            </section>
            {/*<div className="section-divider-wrap">
                <div className="section-divider-gray"/>
            </div>*/}
            <section id="reviews" className={styles.reviews}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Отзывы</h4>
                    </div>
                    <Reviews/>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
