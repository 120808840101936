import React from 'react';

import PhoneIcon from '@mui/icons-material/Phone';
import {Dialog} from '@mui/material';

import telegramLogoBlue from '../../../assets/icons/telegram_blue.svg';
import vkLogoBlue from '../../../assets/icons/vk_blue.svg';
import {CONTACTS_MODAL} from '../../../constants/constants';
import {useModalContext} from '../../../system/context/ModalContext';

import styles from './ContactsForm.module.scss';

type Props = {
    title?: string;
    btnText?: string;
    pageName?: string;
};

export function ContactsFormV2Modal() {
    const {openedKey, closeModal, data} = useModalContext();

    const contactsOpen = openedKey === CONTACTS_MODAL;

    const handleCloseModal = () => {
        closeModal();
    };

    return (
        <Dialog open={contactsOpen} onClose={handleCloseModal}>
            <div className={styles.modal} style={{background: 'white'}}>
                <h1 className={styles.modalTitle}>Контакты для записи</h1>
                <div>
                    <div className={styles.phone}>
                        <PhoneIcon color="inherit" fontSize="large"/>
                        <a className={styles.phoneLink} href="tel:+79606332007">+7 (960) 633-20-07</a>
                    </div>
                    <div className={styles.socials}>
                        <div
                            className={styles.socialIconWrap}
                            onClick={() => window.open('https://vk.com/titangym_belgorod')}
                        >
                            <img className={styles.socialIcon} src={vkLogoBlue} alt="Titan Gym VK"/>
                        </div>
                        <div
                            className={styles.socialIconWrap}
                            onClick={() => window.open('https://t.me/titangym_belgorod')}
                        >
                            <img className={styles.socialIcon} src={telegramLogoBlue} alt="Titan Gym Telegram"/>
                        </div>
                    </div>
                    <p style={{ textAlign: 'center' }}>Ждем вас в тренажерном зале =)</p>
                </div>
            </div>
        </Dialog>
    );
}
