import React from 'react';
import {Helmet} from 'react-helmet';

import styles from './PrivacyPolicyPage.module.scss';

export default function PrivacyPolicyPage() {
    return (
        <div>
            <Helmet>
                <title>Политика конфиденциальности</title>
            </Helmet>
            <section className={styles.document}>
                <div className="container">
                    <div>
                        <h1 className={styles.sectionTitle}>Политика конфиденциальности</h1>
                    </div>
                    <div itemProp="articleBody">
                        <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                        <p>Политика в отношении обработки персональных данных (Политика конфиденциальности) (далее –
                            Политика) разработана в соответствии с Федеральным законом от 27.07.2006. №152-ФЗ «О
                            персональных данных» (далее – ФЗ-152).<br/>Настоящая Политика определяет порядок обработки
                            персональных данных и меры по обеспечению безопасности персональных данных в ООО «ТИТАН
                            СПОРТ» (далее – Оператор) с целью защиты прав и свобод человека и гражданина при
                            обработке его персональных данных, в том числе защиты прав на неприкосновенность частной
                            жизни, личную и семейную тайну.</p>
                        <p>В Политике используются следующие основные понятия:</p>
                        <p>- автоматизированная обработка персональных данных – обработка персональных данных с помощью
                            средств вычислительной техники;</p>
                        <p>- блокирование персональных данных - временное прекращение обработки персональных данных (за
                            исключением случаев, если обработка необходима для уточнения персональных данных);</p>
                        <p>- информационная система персональных данных - совокупность содержащихся в базах данных
                            персональных данных, и обеспечивающих их обработку информационных технологий и технических
                            средств;</p>
                        <p>- обезличивание персональных данных - действия, в результате которых невозможно определить
                            без использования дополнительной информации принадлежность персональных данных конкретному
                            субъекту персональных данных;</p>
                        <p>- обработка персональных данных - любое действие (операция) или совокупность действий
                            (операций), совершаемых с использованием средств автоматизации или без использования таких
                            средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
                            уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
                            предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
                            данных;</p>
                        <p>- оператор - государственный орган, муниципальный орган, юридическое или физическое лицо,
                            самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
                            персональных данных, а также определяющие цели обработки персональных данных, состав
                            персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными
                            данными;</p>
                        <p>- персональные данные – любая информация, относящаяся к прямо или косвенно определенному или
                            определяемому физическому лицу (субъекту персональных данных);</p>
                        <p>- предоставление персональных данных – действия, направленные на раскрытие персональных
                            данных определенному лицу или определенному кругу лиц;</p>
                        <p>- распространение персональных данных - действия, направленные на раскрытие персональных
                            данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с
                            персональными данными неограниченного круга лиц, в том числе обнародование персональных
                            данных в средствах массовой информации, размещение в информационно- телекоммуникационных
                            сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
                        <p>- трансграничная передача персональных данных - передача персональных данных на территорию
                            иностранного государства органу власти иностранного государства, иностранному физическому
                            или иностранному юридическому лицу.</p>
                        <p>- уничтожение персональных данных - действия, в результате которых невозможно восстановить
                            содержание персональных данных в информационной системе персональных данных и (или) в
                            результате которых уничтожаются материальные носители персональных данных.</p>
                        <p>- сервис – мобильные приложения, сайты и прочие интернет-ресурсы, принадлежащие
                            Оператору.</p>
                        <p>- пользователь - лицо, обладающее право- и дееспособностью, осуществляющее доступ к сервисам
                            Оператора.</p>
                        <p><br/>2. ПРИНЦИПЫ, ЦЕЛИ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                        <p>2.1. Принципы обработки персональных данных</p>
                        <p>Обработка персональных данных у Оператора осуществляется на основе следующих принципов:<br/>-
                            обработка персональных данных осуществляется на законной и справедливой основе;<br/>-
                            обработка персональных данных ограничивается достижением конкретных, заранее
                            определенных и законных целей. Не допускается обработка персональных данных,
                            несовместимая с целями сбора персональных данных;<br/>- не допускается объединение баз
                            данных, содержащих персональные данные, обработка которых осуществляется в целях,
                            несовместимых между собой;<br/>- обработке подлежат только персональные данные,
                            которые отвечают целям их обработки;<br/>- содержание и объем обрабатываемых
                            персональных данных соответствуют заявленным целям обработки и не являются
                            избыточными по отношению к заявленным целям их обработки;<br/>- при обработке
                            персональных данных обеспечивается точность персональных данных, их
                            достаточность, а в необходимых случаях и актуальность по отношению к
                            целям обработки персональных данных. Принимаются необходимые меры по
                            удалению или уточнению неполных или неточных данных;<br/>- хранение
                            персональных данных осуществляется в форме, позволяющей определить
                            субъекта персональных данных, не дольше, чем этого требуют цели
                            обработки персональных данных, если срок хранения персональных
                            данных не установлен федеральным законом, договором, стороной
                            которого, выгодоприобретателем или поручителем по которому является
                            субъект персональных данных. Обрабатываемые персональные данные по
                            достижении целей обработки или в случае утраты необходимости в
                            достижении этих целей, если иное не предусмотрено федеральным
                            законом, подлежат уничтожению либо обезличиванию;<br/>- при сборе
                            персональных данных, в том числе посредством информационно-
                            телекоммуникационной сети «Интернет», обеспечивается запись,
                            систематизация, накопление, хранение, уточнение (обновление,
                            изменение), извлечение персональных данных граждан Российской
                            Федерации с использование баз данных, находящихся на территории
                            Российской Федерации.</p>
                        <p>2.2. Цели обработки персональных данных</p>
                        <p>Оператор собирает, хранит и обрабатывает только те персональные данные, которые необходимы
                            для оказания услуг и для осуществления своей деятельности, а также для обеспечения прав и
                            законных интересов третьих лиц при условии, что при этом не нарушаются права субъекта
                            персональных данных.<br/>Персональные данные субъекта персональных данных могут быть
                            обработаны в следующих целях:<br/>– для идентификации субъекта ПДн;<br/>– для связи с
                            субъектом персональных данных в случае необходимости, в том числе для направления
                            уведомлений, запросов и информации, связанной с оказанием услуг, рекламной
                            информации, а также для обработки запросов и заявок от субъектов персональных
                            данных;<br/>– для проведения статистических и иных исследований на основе
                            обезличенных данных.</p>
                        <p>Оператор не обрабатывает специальные категории персональных данных, касающихся расовой,
                            национальной принадлежности, политических взглядов, религиозных убеждений, состояния
                            здоровья, а также биометрические персональные данные.<br/>Обработка персональных данных
                            осуществляется Оператором с согласия субъектов персональных данных, как с использованием
                            средств автоматизации, так и без использования таких средств.<br/>Оператор не
                            предоставляет и не раскрывает сведения, содержащие персональные данные субъектов
                            персональных данных, третьей стороне без письменного согласия субъекта персональных
                            данных, за исключением случаев, когда это необходимо в целях предупреждения угрозы
                            жизни и здоровью, а также в случаях, установленных действующим законодательством в
                            области защиты персональных данных.<br/>По мотивированному запросу уполномоченного
                            органа и исключительно в рамках выполнения действующего законодательства
                            персональные данные субъекта без его согласия могут быть переданы:<br/>– в
                            судебные органы в связи с осуществлением правосудия;<br/>– в органы
                            федеральной службы безопасности;<br/>– в органы прокуратуры;<br/>– в
                            органы полиции;<br/>– в иные органы и организации в случаях,
                            установленных нормативными правовыми актами, обязательными для
                            исполнения.<br/>В случае получения согласия на обработку
                            персональных данных от представителя субъекта персональных
                            данных полномочия данного представителя на дачу согласия от
                            имени субъекта персональных данных проверяются
                            Оператором.<br/>В случае отзыва субъектом персональных данных
                            согласия на обработку персональных данных Оператор
                            вправе продолжить обработку персональных данных без
                            согласия субъекта при наличии оснований, указанных в
                            действующем законодательстве</p>
                        <p>Правовое регулирование порядка и сроков хранения документов, содержащих персональные данные
                            субъектов персональных данных, осуществляется на основе «Перечня типовых управленческих
                            архивных документов, образующихся в процессе деятельности государственных органов, органов
                            местного самоуправления и организаций, с указанием сроков хранения», утвержденного Приказом
                            Минкультуры России от 25.08.2010 г. № 558.<br/>Уничтожение документов, содержащих
                            персональные данные, производится любым способом, исключающим ознакомление посторонних
                            лиц с уничтожаемыми материалами и возможность восстановления их текста.</p>
                        <p>2.3. Условия обработки персональных данных</p>
                        <p>Оператор производит обработку персональных данных при наличии хотя бы одного из следующих
                            условий:<br/>- обработка персональных данных осуществляется с согласия субъекта персональных
                            данных на обработку его персональных данных;<br/>- обработка персональных данных
                            необходима для достижения целей, предусмотренных международным договором Российской
                            Федерации или законом, для осуществления и выполнения возложенных законодательством
                            Российской Федерации на оператора функций, полномочий и обязанностей;<br/>- обработка
                            персональных данных необходима для осуществления правосудия, исполнения
                            судебного акта, акта другого органа или должностного лица, подлежащих исполнению
                            в соответствии с законодательством Российской Федерации об исполнительном
                            производстве;<br/>- обработка персональных данных необходима для исполнения
                            договора, стороной которого либо выгодоприобретателем или поручителем по
                            которому является субъект персональных данных, а также для заключения
                            договора по инициативе субъекта персональных данных или договора, по
                            которому субъект персональных данных будет являться выгодоприобретателем или
                            поручителем;<br/>- обработка персональных данных необходима для осуществления
                            прав и законных интересов оператора или третьих лиц либо для достижения
                            общественно значимых целей при условии, что при этом не нарушаются права
                            и свободы субъекта персональных данных;<br/>- осуществляется обработка
                            персональных данных, доступ неограниченного круга лиц к которым
                            предоставлен субъектом персональных данных либо по его просьбе
                            (далее<br/>- общедоступные персональные данные);<br/>- осуществляется
                            обработка персональных данных, подлежащих опубликованию или
                            обязательному раскрытию в соответствии с федеральным законом.
                        </p>
                        <p>2.4. Конфиденциальность персональных данных</p>
                        <p>Информация, относящаяся к персональным данным, ставшая известной в процессе деятельности
                            Оператора, является конфиденциальной информацией и охраняется действующим законодательством.<br/>Лица,
                            получившие доступ к обрабатываемым персональным данным, подписали обязательство о
                            неразглашении конфиденциальной информации, а также предупреждены о возможной
                            дисциплинарной, административной, гражданско-правовой и уголовной ответственности в
                            случае нарушения норм и требований действующего законодательства Российской Федерации в
                            области защиты персональных данных.<br/>Лица, получившие доступ к обрабатываемым
                            персональным данным, не имеют права сообщать персональные данные субъекта
                            персональных данных третьей стороне без письменного согласия такого субъекта, за
                            исключением случаев, когда это необходимо в целях предупреждения угрозы жизни и
                            здоровью субъекта персональных данных, а также в случаях, установленных
                            законодательством РФ.<br/>Лица, получившие доступ к персональным данным, обязуются не
                            сообщать персональные данные в коммерческих целях без письменного согласия
                            субъекта персональных данных. Обработка персональных данных субъектов
                            персональных данных в целях продвижения товаров, работ, услуг на рынке путем
                            осуществления прямых контактов с потенциальным потребителем с помощью средств
                            связи допускается только с его предварительного согласия.</p>
                        <p>2.5. Общедоступные источники персональных данных</p>
                        <p>В целях информационного обеспечения у Оператора могут создаваться общедоступные источники
                            персональных данных субъектов персональных данных, в том числе справочники и адресные книги.
                            В общедоступные источники персональных данных с письменного согласия субъекта персональных
                            данных могут включаться его фамилия, имя, отчество, дата рождения, должность, номера
                            контактных телефонов, адрес электронной почты и иные персональные данные, сообщаемые
                            субъектом персональных данных.<br/>Сведения о субъекте персональных данных должны быть в
                            любое время исключены из общедоступных источников персональных данных по требованию
                            субъекта персональных данных, уполномоченного органа по защите прав субъектов
                            персональных данных либо по решению суда.</p>
                        <p>2.6. Специальные категории персональных данных</p>
                        <p>Обработка Оператором специальных категорий персональных данных, касающихся расовой,
                            национальной принадлежности, политических взглядов, религиозных или философских убеждений,
                            состояния здоровья, интимной жизни, допускается в случаях, если:<br/>- субъект персональных
                            данных дал согласие в письменной форме на обработку своих персональных данных;<br/>-
                            персональные данные сделаны общедоступными субъектом персональных данных;<br/>-
                            обработка персональных данных осуществляется в соответствии с законодательством
                            о государственной социальной помощи, трудовым законодательством,
                            законодательством Российской Федерации о пенсиях по государственному пенсионному
                            обеспечению, о трудовых пенсиях;<br/>- обработка персональных данных необходима
                            для защиты жизни, здоровья или иных жизненно важных интересов субъекта
                            персональных данных либо жизни, здоровья или иных жизненно важных интересов
                            других лиц и получение согласия субъекта персональных данных невозможно;<br/>-
                            обработка персональных данных осуществляется в медико-профилактических
                            целях, в целях установления медицинского диагноза, оказания медицинских
                            и медико- социальных услуг при условии, что обработка персональных
                            данных осуществляется лицом, профессионально занимающимся медицинской
                            деятельностью и обязанным в соответствии с законодательством Российской
                            Федерации сохранять врачебную тайну;<br/>- обработка персональных данных
                            необходима для установления или осуществления прав субъекта
                            персональных данных или третьих лиц, а равно и в связи с
                            осуществлением правосудия;<br/>- обработка персональных данных
                            осуществляется в соответствии с законодательством об
                            обязательных видах страхования, со страховым
                            законодательством.<br/>Обработка специальных категорий
                            персональных данных, осуществлявшаяся в случаях,
                            предусмотренных пунктом 4 статьи 10 ФЗ-152 должна быть
                            незамедлительно прекращена, если устранены причины,
                            вследствие которых осуществлялась их обработка, если иное не
                            установлено федеральным законом.<br/>Обработка персональных
                            данных о судимости может осуществляться Оператором
                            исключительно в случаях и в порядке, которые
                            определяются в соответствии с федеральными законами.</p>
                        <p>2.7. Биометрические персональные данные</p>
                        <p>Сведения, которые характеризуют физиологические и биологические особенности человека, на
                            основании которых можно установить его личность - биометрические персональные данные - могут
                            обрабатываться Оператором только при наличии согласия субъекта персональных данных в
                            письменной форме.</p>
                        <p>2.8. Поручение обработки персональных данных другому лицу</p>
                        <p>Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта
                            персональных данных, если иное не предусмотрено федеральным законом, на основании
                            заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по
                            поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных,
                            предусмотренные ФЗ-152 и настоящей Политико</p>
                        <p>2.9. Обработка персональных данных граждан Российской Федерации</p>
                        <p>В соответствии со статьей 2 Федерального закона от 21 июля 2014 года N 242-ФЗ «О внесении
                            изменений в отдельные законодательные акты Российской Федерации в части уточнения порядка
                            обработки персональных данных в информационно-телекоммуникационных сетях» при сборе
                            персональных данных, в том числе посредством информационно-телекоммуникационной сети
                            "Интернет", оператор обязан обеспечить запись, систематизацию, накопление, хранение,
                            уточнение (обновление, изменение), извлечение персональных данных граждан Российской
                            Федерации с использованием баз данных, находящихся на территории Российской Федерации, за
                            исключением случаев:<br/>- обработка персональных данных необходима для достижения целей,
                            предусмотренных международным договором Российской Федерации или законом, для
                            осуществления и выполнения возложенных законодательством Российской Федерации на
                            оператора функций, полномочий и обязанностей;<br/>- обработка персональных данных
                            необходима для осуществления правосудия, исполнения судебного акта, акта другого
                            органа или должностного лица, подлежащих исполнению в соответствии с
                            законодательством Российской Федерации об исполнительном производстве (далее -
                            исполнение судебного акта);<br/>- обработка персональных данных необходима для
                            исполнения полномочий федеральных органов исполнительной власти, органов
                            государственных внебюджетных фондов, исполнительных органов государственной
                            власти субъектов Российской Федерации, органов местного самоуправления и функций
                            организаций, участвующих в предоставлении соответственно государственных и
                            муниципальных услуг, предусмотренных Федеральным законом от 27 июля 2010 года N
                            210-ФЗ «Об организации предоставления государственных и муниципальных услуг»,
                            включая регистрацию субъекта персональных данных на едином портале
                            государственных и муниципальных услуг и (или) региональных порталах
                            государственных и муниципальных услуг;<br/>- обработка персональных данных
                            необходима для осуществления профессиональной деятельности журналиста и
                            (или) законной деятельности средства массовой информации либо научной,
                            литературной или иной творческой деятельности при условии, что при этом не
                            нарушаются права и законные интересы субъекта персональных данных.</p>
                        <p>2.10. Трансграничная передача персональных данных</p>
                        <p>Оператор обязан убедиться в том, что иностранным государством, на территорию которого
                            предполагается осуществлять передачу персональных данных, обеспечивается адекватная защита
                            прав субъектов персональных данных, до начала осуществления такой передачи.<br/>Трансграничная
                            передача персональных данных на территории иностранных государств, не обеспечивающих
                            адекватной защиты прав субъектов персональных данных, может осуществляться в
                            случаях:<br/>- наличия согласия в письменной форме субъекта персональных данных на
                            трансграничную передачу его персональных данных;<br/>- исполнения договора, стороной
                            которого является субъект персональных данных.</p>
                        <p><br/>3. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                        <p>3.1. Согласие субъекта персональных данных на обработку его персональных данных</p>
                        <p>Субъект персональных данных принимает решение о предоставлении его персональных данных и дает
                            согласие на их обработку свободно, своей волей и в своем интересе. Согласие на обработку
                            персональных данных может быть дано субъектом персональных данных или его представителем в
                            любой позволяющей подтвердить факт его получения форме, если иное не установлено федеральным
                            законом.</p>
                        <p>3.2. Права субъекта персональных данных</p>
                        <p>Субъект персональных данных имеет право на получение у Оператора информации, касающейся
                            обработки его персональных данных, если такое право не ограничено в соответствии с
                            федеральными законами. Субъект персональных данных вправе требовать от Оператора уточнения
                            его персональных данных, их блокирования или уничтожения в случае, если персональные данные
                            являются неполными, устаревшими, неточными, незаконно полученными или не являются
                            необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры
                            по защите своих прав.<br/>Обработка персональных данных в целях продвижения товаров, работ,
                            услуг на рынке путем осуществления прямых контактов с субъектом персональных данных
                            (потенциальным потребителем) с помощью средств связи, а также в целях политической
                            агитации допускается только при условии предварительного согласия субъекта персональных
                            данных.<br/>Оператор обязан немедленно прекратить по требованию субъекта персональных
                            данных обработку его персональных данных в вышеуказанных целях.<br/>Запрещается
                            принятие на основании исключительно автоматизированной обработки персональных
                            данных решений, порождающих юридические последствия в отношении субъекта
                            персональных данных или иным образом затрагивающих его права и законные
                            интересы, за исключением случаев, предусмотренных федеральными законами, или при
                            наличии согласия в письменной форме субъекта персональных данных.<br/>Если
                            субъект персональных данных считает, что Оператор осуществляет обработку его
                            персональных данных с нарушением требований ФЗ-152 или иным образом нарушает
                            его права и свободы, субъект персональных данных вправе обжаловать действия
                            или бездействие Оператора в Уполномоченный орган по защите прав субъектов
                            персональных данных или в судебном порядке.<br/>Субъект персональных данных
                            имеет право на защиту своих прав и законных интересов, в том числе на
                            возмещение убытков и (или) компенсацию морального вреда.</p>
                        <p><br/>4. ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                        <p>Безопасность персональных данных, обрабатываемых Оператором, обеспечивается реализацией
                            правовых, организационных и технических мер, необходимых для обеспечения требований
                            федерального законодательства в области защиты персональных данных.<br/>Для предотвращения
                            несанкционированного доступа к персональным данным Оператором применяются следующие
                            организационно-технические меры:<br/>- назначение должностных лиц, ответственных за
                            организацию обработки и защиты персональных данных;<br/>- утверждение локальных
                            актов, устанавливающих процедуры, направленные на выявление и предотвращение
                            нарушений законодательства Российской Федерации в сфере персональных данных, а
                            также определяющие для каждой цели обработки персональных данных содержание
                            обрабатываемых персональных данных, категории субъектов, персональные данные
                            которых обрабатываются, сроки их обработки и хранения, порядок уничтожения при
                            достижении целей обработки или при наступлении иных законных оснований;<br/>-
                            применение предусмотренных соответствующими нормативными правовыми актами
                            правовые, организационные и технические меры по обеспечению безопасности
                            персональных данных при их обработке в информационных системах персональных
                            данных Оператора.<br/>- при обработке персональных данных, осуществляемой без
                            использования средств автоматизации, выполняются требования,
                            установленные Постановлением Правительства Российской Федерации от 15
                            сентября 2008 г. № 687 «Об утверждении Положения об особенностях
                            обработки персональных данных, осуществляемой без использования средств
                            автоматизации»;<br/>- в целях осуществления внутреннего контроля
                            соответствия обработки персональных данных установленным требованиям
                            действующего законодательства организовано проведение периодических
                            проверок условий обработки персональных данных.</p>
                        <p><br/>5. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ В СЕРВИСАХ ОПЕРАТОРА</p>
                        <p>5.1. Информация о пользователях сервисов, которую получает Оператор</p>
                        <p>В рамках настоящей Политики под информацией о Пользователе (далее — «Информация»)
                            понимаются:</p>
                        <p>• Информация, которую Пользователь предоставляет о себе при регистрации или в процессе
                            использования интернет-сервисов (далее – «Сервис», «Сервисы»), а также комментарии
                            Пользователя, предоставленные и/или добавленные с помощью Сервисов.</p>
                        <p>•
                            Обязательная&nbsp; &nbsp;для&nbsp; &nbsp;предоставления&nbsp; &nbsp;информация&nbsp; &nbsp;обозначена&nbsp; &nbsp; специальным&nbsp; &nbsp;образом,
                            ее предоставление является обязательным условием получения доступа к Сервисам. Иная
                            информация предоставляется Пользователем на его усмотрение. Оператору исходит из того, что
                            Пользователь предоставляет достоверную и достаточную информацию и поддерживает эту
                            информацию в актуальном состоянии.</p>
                        <p>• Информация, которая в процессе использования Пользователем Сервисов (либо в связи с
                            использованием Сервисов) автоматически передается Сервисам устройствами Пользователя
                            посредством установленного на таких устройствах программного обеспечения. К такой информации
                            в том числе, но не ограничиваясь, относятся: IP-адрес Пользователя, тип просмотровой
                            программы Пользователя, адрес запрошенного Пользователем Сервиса и/ или интернет-ресурса,
                            дата и время запроса Сервиса и иная подобная информация.</p>
                        <p>• Иная информация о Пользователе, получение которой предусмотрено условиями использования
                            отдельных Сервисов Оператора.</p>
                        <p>5.2. Использование файлов Cookie</p>
                        <p>При доступе Пользователя к Сервисам может применяться технология cookies в целях
                            автоматической авторизации Пользователя, а также для сбора статистических данных, в
                            частности о посещаемости Сервисов.<br/>Такое отслеживание может производиться как с
                            использованием cookie-файлов, так и без них.<br/>Cookie-файл — это небольшой фрагмент
                            информации, который передается в браузер на компьютере Пользователя или мобильном
                            устройстве.<br/>Основные cookie-файлы передаются через собственный домен Оператора.
                            Cookie-файлы, принадлежащие Оператору, являются основными. Если Оператор
                            позволяет другим организациям использовать cookie-файлы через Сайт, они
                            называются «сторонние cookie-файлы».<br/>Различаются сеансовые и постоянные
                            cookie-файлы. Сеансовые cookie-файлы существуют до тех пор, пока
                            Пользователь не закроет браузер. У постоянных cookie-файлов более длительный
                            цикл жизни, они не удаляются автоматически при закрытии браузера. Оператор
                            стремится использовать или разрешать использование cookie-файлов с жизненным
                            циклом не более 5 лет. Cookie-файл может существовать дольше только в
                            исключительных случаях, например, в целях безопасности, и только когда это
                            необходимо.<br/>Помимо cookie-файлов используются другие похожие технологии
                            отслеживания. К ним могут относиться, например, веб-маяки (также
                            известные как пиксельные теги, веб-жучки или gif- файлы), которые
                            отслеживают URL-адреса или средства разработки программного обеспечения
                            (Software Development Kit, SDK). Веб-маяк — это микроскопическое
                            изображение размером всего в 1 пиксель, которое попадает на компьютер
                            Пользователя через электронное сообщение в формате HTML. Оператор
                            использует эти пиксели, чтобы получить информацию об устройстве
                            Пользователя: например, тип устройства, операционная система, IP-адрес и
                            время посещения Сервиса. Веб-маяки также могут использоваться для
                            доставки и прочтения cookie-файлов на устройстве Пользователя.
                            Отслеживание URL-адресов нужно, чтобы знать, с какого сайта пользователь
                            попадает на Сайт или SDK — это короткий код, внедренный в устройства,
                            который функционирует так же, как cookie-файлы и веб-маяки.<br/>Все
                            технологии в совокупности, упомянутые выше, далее именуются
                            «cookie-файлы».<br/>На основании сookie-файлов Пользователь
                            распознается как один и тот же пользователь на разных страницах
                            Сервисов, на разных Сервисах. Информация, которая собирается с
                            помощью cookie-файлов, включает в себя IP-адрес,
                            идентификационный номер устройства, просмотренные страницы, тип
                            браузера, информацию о поиске через браузер, операционную
                            систему, интернет- провайдер, временную метку клика,
                            отреагировали ли вы на рекламу, соответствующий URL- адрес,
                            использованные на странице/в приложении функции или предпринятые
                            действия.<br/>Сервисы используют cookie-файлы для различных
                            целей:<br/>• Технические cookie-файлы используются для
                            предоставления Пользователям современного и удобного
                            сайта, который автоматически приспосабливается к
                            потребностям и желаниям Пользователей используются
                            технические cookie-файлы, что позволяет показывать
                            Сервис, обеспечивать его исправную работу, создавать
                            аккаунт пользователя, давать возможность входить в
                            аккаунт. Технические cookie-файлы строго необходимы
                            Сервисам для бесперебойной работы.<br/>• Функциональные
                            cookie-файлы используются функциональные
                            cookie-файлы, чтобы запоминать предпочтения
                            Пользователей и помогать им использовать Сервис
                            эффективно. Функциональные cookie-файлы используются
                            для запоминания информацию о Пользователе для входа
                            в систему, для автоматического ввода имени и пароля
                            Пользователя. При этом пароли хранятся в
                            зашифрованном виде. Функциональные cookie- файлы не
                            строго необходимы для работы Сервиса или приложения,
                            но они добавляют функциональность для Пользователя,
                            делая Сервис более удобным в использовании.<br/>•
                            Аналитические cookie-файлы используются, чтобы
                            определять, как Пользователи используют Сервис.
                            Они помогают оптимизировать Сервис и улучшать
                            его работу, анализировать эффективность
                            рекламных кампаний и информационных материалов,
                            определять, что Сервис является интересным и
                            полезным для Пользователей. Аналитические
                            cookie-файлы собирают информацию в том числе о
                            том, какие страницы посещает Пользователь, с
                            каких страниц переходит на какие и с каких
                            покидает Сервис, о том, каким типом платформы
                            пользуется Пользователь, какие электронные
                            письма открывал и на что в них «нажимал», о дате
                            и времени использования Сервиса, как
                            Пользователь взаимодействует с Сервисом, в
                            частности, количество кликов на странице,
                            движения мыши и прокрутку, поисковые слова и
                            другой текст, который Пользователь вводит в
                            разные поля. При проведении рекламных кампаний в
                            интернете используются аналитические
                            cookie-файлы, чтобы узнать, как Пользователи
                            взаимодействуют с Сервисом после того, как они
                            увидели рекламное объявление (в том числе на
                            сторонних сайтах). Аналитические cookie-файлы
                            используются также, чтобы Партнеры Оператора
                            могли узнать, воспользовались ли их клиенты
                            предложениями, интегрированными на их
                            сайтах.<br/>• Коммерческие cookie-файлы
                            (сторонние, а также собственные)
                            используются, с целью показа рекламных
                            объявлений на Сервисе и других платформах,
                            основываются на действиях, которые
                            Пользователь совершает в интернете.<br/>Через
                            настройки браузера (например, Оpera,
                            Internet Edge, Safari, Firefox, Chrome)
                            Пользователь может выбрать, какие
                            cookie-файлы принимать, какие —
                            отклонять. Расположение настроек зависит
                            от используемого браузера, для поиска
                            необходимых настроек используется
                            функция «Справка»/«Помощь» в
                            используемом браузере.<br/>При отказе от
                            определенных технических и/или
                            функциональных cookie-файлов,
                            Пользователю могут быть недоступны
                            некоторые функции Сервиса.<br/>Аналитика.
                            Чтобы проконтролировать сбор
                            данных в аналитических целях
                            службой Google Analytics из
                            определенных типов браузеров,
                            необходимо посетить страницу
                            «Блокировщик Google Analytics»
                            (доступно только со
                            стационарного компьютера). Чтобы
                            проконтролировать сбор данных в
                            аналитических целях службой
                            Яндекс.Метрика (пикселем
                            слежения, принадлежащим
                            российскому поисковому ресурсу
                            Яндекс), необходимо посетить
                            страницу «yandex.com». Для
                            отказа от персонализированной
                            рекламы Яндекса необходимо
                            посетить указанную страницу и
                            убрать галочку напротив поля
                            «Учитывать мои интересы».</p>
                        <p>5.3. Изменение, удаление Пользователем информации</p>
                        <p>Пользователь может в любой момент изменить, а также обновить или дополнить предоставленную им
                            Информацию или ее часть, а также параметры ее конфиденциальности, обратившись к Оператору в
                            службу поддержки либо используя Сервисы.<br/>Пользователь может удалить предоставленную им
                            Информацию, но при этом он должен учитывать, что удаление такой Информации может повлечь
                            невозможность использования Сервисов и расторжение договора с Пользователем</p>
                        <p>5.4. Меры, принимаемые для защиты информации Пользователя</p>
                        <p>Оператор принимает необходимые и достаточные организационные, правовые и технические меры для
                            защиты Информации от неправомерного доступа, уничтожения, изменения, блокирования,
                            копирования, распространения, а также от иных неправомерных действий с ней третьих лиц в
                            пределах компетенции Оператора.</p>
                        <p>В рамках обеспечения безопасности Информации Оператор выполняет,&nbsp; в том числе, но не
                            ограничиваясь, следующие действия:<br/>• использует SSL-шифрование;<br/>• производит
                            идентификацию Пользователя по логину и паролю для доступа к Сервисам, предоставляемым
                            Оператором;<br/>• постоянно совершенствует способы сбора, хранения и получения
                            Информации, для противодействия несанкционированному доступу к Сервисам;<br/>•
                            предоставляет доступ к Информации только ограниченному кругу работников,
                            подрядчиков, агентов;<br/>• при передаче данных Пользователя о платежах обязуется
                            не использовать их в незаконных целях, а также не вмешиваться и не нарушать
                            порядок работы сетей, обслуживающих платежные системы;<br/>• устанавливает
                            строгие корпоративные и договорные обязательства по соблюдению
                            обеспечения конфиденциальности Информации</p>
                        <p>5.5. Условия обработки информации, передача третьим лицам</p>
                        <p>Оператор хранит&nbsp; &nbsp;и
                            обрабатывает&nbsp; &nbsp;Информацию,&nbsp; &nbsp;полученную&nbsp; &nbsp;с&nbsp; &nbsp;помощью&nbsp; &nbsp;Сервисов,
                            в соответствии с внутренними документами и действующим законодательством Российской
                            Федерации. При отсутствии дальнейшей необходимости в обработке Информации для целей,
                            предусмотренных настоящей Политикой, Оператор удаляет Информацию, находящуюся в его
                            распоряжении. В случае изменения или удаления Информации, Оператор вправе сохранять ее
                            часть&nbsp; &nbsp; &nbsp;для&nbsp; &nbsp; &nbsp;разрешения&nbsp; &nbsp; &nbsp;споров,&nbsp; &nbsp; &nbsp;претензий,&nbsp; &nbsp; &nbsp; исполнения&nbsp; &nbsp; &nbsp; договоров&nbsp; &nbsp; &nbsp;и
                            соглашений с Пользователями, а также для соблюдения всех технических и установленных законом
                            РФ требований и ограничений, связанных с использованием Сервисов.<br/>В отношении Информации
                            сохраняется и обеспечивается ее конфиденциальность, кроме случаев, когда Пользователь
                            добровольно предоставил Информацию неограниченному кругу лиц.<br/>Оператор вправе
                            передать Информацию третьим лицам в следующих случаях:<br/>• Пользователь выразил
                            явно выраженное согласие на такие действия;<br/>• Передача предусмотрена
                            российским и/или иным применимым законодательством;<br/>• В целях обеспечения
                            защиты прав и законных интересов Оператор и/или третьих лиц в случаях,
                            когда действия Пользователя повлекли и/или могут повлечь:<br/>А)
                            причинение либо угрозу причинения убытков иным Пользователям и любым
                            третьим лицам;<br/>Б) введение в заблуждение третьих лиц относительно
                            источника информации (отправителя сообщений любого характера,
                            программ, запросов), если за источник информации выдается
                            Оператор, коим он не является;<br/>В) нарушение авторских и/или
                            исключительных прав третьих лиц на программное
                            обеспечение;<br/>Г) несанкционированный доступ к
                            информационно-вычислительным и сетевым ресурсам
                            Оператора и/или третьих лиц;<br/>Д) нарушение
                            предоставления Сервисов Оператора или ограничение
                            возможностей других пользователей сети Интернет в их
                            получении.</p>
                        <p><br/>6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
                        <p>Иные права и обязанности Оператора в связи с обработкой персональных данных определяются
                            законодательством Российской Федерации в области персональных данных.<br/>Работники
                            Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных
                            данных, несут материальную, дисциплинарную, административную, гражданско- правовую или
                            уголовную ответственность в порядке, установленном федеральными законами.</p>
                    </div>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
