import React from 'react';
import {Helmet} from 'react-helmet';

import Reviews from '../../components/blocks/Reviews/Reviews';
import Team from '../../components/blocks/Team/Team';

import styles from './TeamPage.module.scss';

export default function TeamPage() {
    return (
        <div>
            <Helmet>
                <title>Команда тренеров в Titan Gym Белгород</title>
            </Helmet>
            <section id="team" className={styles.team}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h2 className="section-title">Команда</h2>
                    </div>
                    <Team/>
                </div>
            </section>
            <section id="reviews" className={styles.reviews}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Отзывы</h4>
                    </div>
                    <Reviews/>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
