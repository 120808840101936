import {CONTACTS_MODAL} from '../constants/constants';
import {useModalContext} from '../system/context/ModalContext';

export function useOpenContactsModal() {
    const {openModal, setData} = useModalContext();

    return ({title = 'Оформить заявку', btnText = 'Отправить', pageName = 'Главная', msgDsc = '-'}) => {
        setData({title, btnText, pageName, msgDsc});
        openModal(CONTACTS_MODAL);
    };
}
