import React from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';

import telegramLogoBlue from '../../../assets/icons/telegram_blue.svg';
import vkLogoBlue from '../../../assets/icons/vk_blue.svg';
import logoPng from '../../../assets/img/logo.png';

import styles from './Footer.module.scss';

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles.footerInner}>
                    <div>
                        <img className={styles.logo} src={logoPng} alt=""/>
                    </div>
                    <div className={styles.address}>
                        <LocationOnIcon color="primary" fontSize="large"/>
                        <span style={{marginLeft: 8}}>г. Белгород, ул. Щорса 45а</span>
                    </div>
                    <div className={styles.phone}>
                        <PhoneIcon color="primary" fontSize="large"/>
                        <a className={styles.phoneLink} href="tel:+79606332007">+7 (960) 633-20-07</a>
                    </div>
                    <div className={styles.workingTimes}>
                        <div className={styles.workingTimesTitle}>Режим работы</div>
                        <div className={styles.workingTimesItem}>Пн - Пт: 8:00 - 0:00</div>
                        <div className={styles.workingTimesItem}>Cб: 8:30 - 23:00</div>
                        <div className={styles.workingTimesItem}>Вс: 10:00 - 23:00</div>
                    </div>
                    <div className={styles.socials}>
                        <div
                            className={styles.socialIconWrap}
                            onClick={() => window.open('https://vk.com/titangym_belgorod')}
                        >
                            <img className={styles.socialIcon} src={vkLogoBlue} alt="Titan Gym VK"/>
                        </div>
                        <div
                            className={styles.socialIconWrap}
                            onClick={() => window.open('https://t.me/titangym_belgorod')}
                        >
                            <img className={styles.socialIcon} src={telegramLogoBlue} alt="Titan Gym Telegram"/>
                        </div>
                    </div>
                    <div className={styles.company}>
                        <div>ООО "ТИТАН СПОРТ"</div>
                        <div>ИНН: 3123486731</div>
                        <div>ОГРН: 1223100003114</div>
                    </div>
                    <div className={styles.copyright}>Все права наши (с)</div>
                </div>
            </div>
        </footer>
    );
}
