import React from 'react';

import {Grid} from '@mui/material';

import styles from './Reviews.module.scss';

const reviews = [
    {
        name: 'Андрей Бурлаков',
        img: '/img/gym1.png',
        text: 'В Титане самые приемлемые цены в округе. Хожу уже не первый год, атмосфера этого зала даёт сил и мотивацию двигаться только вперед! Спасибо за большой спектр оборудования, даже в самое загруженное время нет проблем с нехваткой тренажеров.',
    },
    {
        name: 'Татьяна Федорова',
        img: '/img/gym1.png',
        text: 'Очень чистый и уютный тренажерный зал! Здесь хочется заниматься каждый день! Отличная команда тренеров всегда поможет и подскажет. Очень рада, что приобрела годовой абонемент именно в этот клуб. С удовольствием всем его советую!',
    },
    {
        name: 'Даниил Дергунов',
        img: '/img/gym1.png',
        text: 'Данное место является примером безоговорочного трушного зала. Если ты хочешь действительно жать - тебе в Titan Gym. В данном зале действительно хочется жать, приседать. Не потому, что скоро лето и нужно скинуть или набрать несколько килограмм, а потому, что атмосфера этого зала способствует этому очень даже хорошо.',
    },
    /*{
        name: 'Андрей Квадрат',
        img: '/img/gym1.png',
        text: 'Жим, становая, присед. Приходите. Самый лучший зал, приходите пацаны не пожалеете.',
    },*/
];

export default function Reviews() {
    return (
        <div className={styles.reviewsList}>
            <Grid container spacing={3}>
                {reviews.map(review => (
                    <Grid key={review.name} item md={4} sm={12}>
                        <div className={styles.card}>
                            <h5 className={styles.name}>{review.name}</h5>
                            <p className={styles.text}>{review.text}</p>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
