import React from 'react';
import {isMobile} from 'react-device-detect';

import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';

import './Banners.css';

const slides = [
    {
        img: '/img/banners/gym28.jpg',
    },
    {
        img: '/img/banners/gym11.jpg',
    },
];

export default function Banners() {
    return (
        <div className="banners">
            <Swiper
                slidesPerView={isMobile ? 1 : 1}
                centeredSlides={true}
                spaceBetween={isMobile ? 0 : 1}
                grabCursor={true}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                onSlideChange={() => {
                }}
                onSwiper={(swiper) => {
                }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index.toString()}>
                        <div className="slide-image" style={{backgroundImage: `url("${slide.img}")`}}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
