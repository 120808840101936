import React from 'react';
import {Helmet} from 'react-helmet';

import styles from './ConsentPersonalPage.module.scss';

export default function ConsentPersonalPage() {
    return (
        <div>
            <Helmet>
                <title>Согласие на обработку персональных данных</title>
            </Helmet>
            <section className={styles.document}>
                <div className="container">
                    <div>
                        <h1 className={styles.sectionTitle}>Согласие на обработку персональных данных</h1>
                    </div>
                    <div>
                        <p>Настоящим я, во исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О
                            персональных данных» (с изменениями и дополнениями) свободно, своей волей и своем интересе,
                            а также подтверждая свою дееспособность, даю свое согласие ООО «ТИТАН СПОРТ» (ИНН
                            3123486731), (далее – Общество), сайт
                            Общества: titan31.ru на обработку своих персональных данных со следующими
                            условиям:<br/>1. Данное Согласие дается на обработку персональных данных, как без
                            использования средств автоматизации, так и с их использованием. Согласие дается на
                            обработку следующих моих персональных данных: фамилия, имя, отчество; номер телефона
                            (домашний/ сотовый); электронная почта; пользовательские данные (сведения о
                            местоположении, тип и версия ОС, тип и версия Браузера, тип устройства и разрешение его
                            экрана; источник, откуда пришел на сайт пользователь; с какого сайта или по какой
                            рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает
                            пользователь; ip-адрес); данные обо мне, которые станут известны в ходе исполнения
                            договоров (в случае заключения договора между мной и Обществом), а также иная
                            общедоступная информация обо мне.<br/>2. Персональные данные не являются
                            общедоступными.<br/>3. Цель обработки персональных данных: обработка входящих
                            запросов физических лиц с целью оказания консультирования по различным вопросам,
                            относящимся к сфере предпринимательской деятельности Общества; аналитики
                            действий физического лица на вебсайте и функционирования веб-сайта.<br/>4.
                            Основанием для обработки персональных данных является статья 24 Конституции
                            Российской Федерации; статья 6 Федерального закона № 152-ФЗ «О персональных
                            данных»; настоящее Согласие посетителя сайта на обработку персональных
                            данных.<br/>5. В ходе обработки с персональными данными будут совершены
                            следующие действия: сбор, запись, систематизация, накопление, хранение,
                            уточнение (обновление, изменение), извлечение, использование, передача
                            (распространение, предоставление, доступ), блокирование, удаление,<br/>уничтожение.<br/>6.
                            Настоящим я уведомлен Обществом, что предполагаемыми пользователями
                            персональных данных являются работники Общества, а также лица,
                            привлеченные Обществом на условиях гражданско-правового
                            договора.<br/>7. Я ознакомлен (а), что:<br/>7.1. настоящее Согласие на
                            обработку моих персональных данных является бессрочным и может
                            быть отозвано посредством направления мною в адрес Общества
                            письменного заявления. Датой отзыва считается день, следующий за
                            днем вручения Обществу письменного заявления об отзыве Согласия
                            пользователя сайта на обработку персональных данных;<br/>7.2.
                            имею право на доступ к моим персональным данным, требовать
                            уточнения (обновление, изменение) моих персональных данных,
                            а также удаления и уничтожения моих персональных данных в
                            случае их обработки Обществом, нарушающих мои законные права
                            и интересы, законодательство Российской Федерации.<br/>7.3. в
                            случае отзыва Согласия на обработку персональных данных
                            Общество вправе продолжить обработку персональных данных
                            без моего согласия при наличии оснований, указанных в
                            пунктах 2.11 части 1 статьи 6, части 2 статьи 10 и части
                            2 статьи 11 Федерального закона №152-ФЗ «О персональных
                            данных» от 27.07.2006 г.<br/>8. Я обязуюсь в случае
                            изменения моих персональных данных, сведений обо мне
                            незамедлительно сообщить Обществу, с предоставлением
                            подтверждающих документов.<br/>9. Настоящим Согласием
                            я подтверждаю, что являюсь субъектом
                            предоставляемых персональных данных, а также
                            подтверждаю достоверность предоставляемых
                            данных.<br/>10. Настоящее Согласие действует все
                            время до момента прекращения обработки
                            персональных данных, согласно п. 7.1
                            Согласия.</p>
                    </div>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
