import React from 'react';

import {days, events} from '../../../constants/schedule';

import styles from './ScheduleDesktop.module.scss';

type Props = {
    colorText?: string;
    background?: string;
};

export default function ScheduleDesktop({colorText = 'white', background = 'black'}: Props) {

    return (
        <div className={styles.scheduleDesktop}>
            <div className={styles.daysList}>
                {days.map((day, index) => (
                    <div className={styles.day} style={{color: colorText}} key={day}>{day}</div>
                ))}
            </div>
            <div className={styles.eventList}>
                {events.map((dayEvents, index) => (
                    <div className={styles.dayEvents} key={index.toString()}>
                        {dayEvents.map((event, index) => (
                            <div
                                className={styles.eventCard}
                                style={{background: event.background}}
                                key={index.toString()}
                            >
                                <div className={styles.name}>{event.name}</div>
                                <div className={styles.time}>{event.time}</div>
                                <div className={styles.trainer}>{event.trainer}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
