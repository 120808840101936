export const mainGalleryImages = [
    {
        img: '/img/gallery/anna2.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/girl1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/alexander1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/nikita1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/style1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/anna3.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/daria_pro1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/nikita_mal1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/style5.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/gym24.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/daria_leb1.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gallery/resep_sell.jpg',
        title: 'Titan Gym',
    },
];

export const gymGalleryImages = [
    {
        img: '/img/zoon/zoon_gym21.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/zoon/zoon_gym22.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/zoon/zoon_gym12.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/zoon/zoon_gym13.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/zoon/zoon_gym31.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/zoon/zoon_gym42.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/gym/gym53.jpg',
        title: 'Titan Gym',
    },
    {
        img: '/img/zoon/zoon_logo1.jpg',
        title: 'Titan Gym',
    },
];

