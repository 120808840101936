import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, IconButton} from '@mui/material';

import Gallery from '../../components/blocks/Gallery/Gellery';
import Reviews from '../../components/blocks/Reviews/Reviews';
import Services from '../../components/blocks/Services/Services';
import {gymGalleryImages} from '../../constants/images';
import {useOpenContactsModal} from '../../hooks/utilsHooks';

import stylesPromo from '../../components/ui/Promo/UiPromo.module.scss';
import stylesService from '../../components/ui/Service/UiService.module.scss';

export default function GymPage() {

    const openContactsModal = useOpenContactsModal();

    return (
        <div>
            <section id="promo" className={stylesService.promo}>
                <div className={stylesPromo.wrapper}>
                    <div className="container">
                        <div className={stylesPromo.info}>
                            {/*<div className={stylesPromo.backBtn} onClick={() => history.goBack()}>
                                <IconButton component="span">
                                    <ArrowBackIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <span>Назад</span>
                            </div>*/}
                            <h1 className={stylesPromo.title}>Тренажерный<br/> зал</h1>
                            <div className={stylesPromo.line}/>
                            <p className={stylesPromo.desc}>
                                TITAN GYM – это сильная атмосфера и разнообразные тренировочные
                                пространства, где каждый найдет оборудование для достижения своих целей.
                                Удобное разделение по зонам и продуманное расположение обеспечивают максимальный
                                комфорт.
                                У нас нет проблем с нехваткой тренажеров и дисков, даже в самое нагруженное время!
                            </p>
                            <Button
                                style={{fontWeight: 600}}
                                variant="contained"
                                onClick={() => openContactsModal({
                                    pageName: 'Тренажерный зал',
                                    msgDsc: 'Хочет бесплатное посещение тренажерного зала',
                                })}
                            >
                                Хочу бесплатное посещение
                            </Button>
                        </div>
                    </div>
                    <div className={stylesPromo.image} style={{backgroundImage: 'url("/img/services/gym.jpg")'}}/>
                </div>
            </section>
            <section id="about" className={stylesService.about}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Titan Gym</h4>
                    </div>
                    <Gallery images={gymGalleryImages} cols={2} galleryClass="gallery-horizontal"/>
                    {/*<div className={stylesAbout.wrapper}>
                        <Grid container>
                            <Grid item width="100%" md={6} sm={12}>
                                <div className={stylesAbout.cardPrimary}>
                                    <div className={stylesAbout.headline}>Эффективные тренировки – все под рукой</div>
                                    <div className={stylesAbout.desc}>
                                        Оборудование в нашем зале грамотно расставлено, поэтому вам не придется искать
                                        нужный тренажер для выполнения очередного упражнения.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" md={6} sm={12}>
                                <div className={stylesAbout.textWrapper}>
                                    <div className={stylesAbout.headline}>Фитнес меняет жизнь</div>
                                    <div className={stylesAbout.desc}>
                                        Регулярные тренировки – это не только красивая фигура и здоровый организм, но
                                        еще и отличное настроение! Занятия ускоряют обмен веществ, способствуют
                                        выработке эндорфинов, дарят энергию и заряд бодрости.<br/>
                                        <br/>
                                        В нашем зале вы пройдете все запланированные этапы тренировки с комфортом и без
                                        потери времени.
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>*/}
                </div>
            </section>
            {/*<section id="slider" className={stylesService.slider}>
                <div className="container container__fluid-mobile">
                    <UiImageSlider slides={[]} color="primary"/>
                </div>
            </section>*/}
            <section id="reviews" className={stylesService.reviews}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Отзывы</h4>
                    </div>
                    <Reviews/>
                </div>
            </section>
            {/*<div className="section-divider-wrap">
                <div className="section-divider-gray"/>
            </div>*/}
            <section id="services" className={stylesService.services}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Другие услуги</h4>
                    </div>
                    <Services min={true} visibleIds={['personal-training', 'mini-groups', 'groups']}/>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
