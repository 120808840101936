import React from 'react';
import {Helmet} from 'react-helmet';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, Grid, IconButton} from '@mui/material';

import Services from '../../components/blocks/Services/Services';
import {useOpenContactsModal} from '../../hooks/utilsHooks';
import {history} from '../../system/navigation/navigationUtils';

import stylesAbout from '../../components/ui/About/UiAbout.module.scss';
import stylesPromo from '../../components/ui/Promo/UiPromo.module.scss';
import stylesService from '../../components/ui/Service/UiService.module.scss';

const slides = [
    {
        img: '/img/gym11.jpg',
    },
    {
        img: '/img/gym11.jpg',
    },
    {
        img: '/img/gym11.jpg',
    },
    {
        img: '/img/gym11.jpg',
    },
];

export default function MassagePage() {

    const openContactsModal = useOpenContactsModal();

    return (
        <div>
            <Helmet>
                <title>Массаж в тренажерном зале Titan Gym Белгород</title>
            </Helmet>
            <section id="promo" className={stylesService.promo}>
                <div className={stylesPromo.wrapper}>
                    <div className="container">
                        <div className={stylesPromo.info}>
                            <div className={stylesPromo.backBtn} onClick={() => history.goBack()}>
                                <IconButton component="span">
                                    <ArrowBackIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <span>Назад</span>
                            </div>
                            <h1 className={stylesPromo.title}>Массаж</h1>
                            <div className={stylesPromo.line}/>
                            <p className={stylesPromo.desc}>
                                TITAN GYM – это сильная атмосфера и разнообразные тренировочные пространства, где каждый
                                найдет оборудование для достижения своих целей. Удобное разделение по зонам и
                                продуманное расположение обеспечивают максимальный комфорт. У нас нет проблем с
                                нехваткой тренажеров и дисков, даже в самое нагруженное время!
                            </p>
                            <Button
                                style={{fontWeight: 600}}
                                variant="contained"
                                onClick={() => openContactsModal({
                                    pageName: 'Массаж',
                                    msgDsc: 'Хочет записаться на массаж',
                                })}
                            >
                                Записаться
                            </Button>
                        </div>
                    </div>
                    <div className={stylesPromo.image} style={{backgroundImage: 'url("/img/services/massage.jpg")'}}/>
                </div>
            </section>
            <section id="about" className={stylesService.about}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Об услуге</h4>
                    </div>
                    <div className={stylesAbout.wrapper}>
                        <Grid container>
                            <Grid item width="100%" md={6} sm={12}>
                                <div className={stylesAbout.cardPrimary}>
                                    <div className={stylesAbout.headline}>Эффективность и безопасность</div>
                                    <div className={stylesAbout.desc}>
                                        Персональный тренер подбирает необходимую нагрузку, следит за правильностью
                                        выполнения упражнений и решает, когда пора увеличивать вес и число повторов. Он
                                        регулярно корректирует тренировочную программу на основании текущих показателей,
                                        чтобы привести вас к целевым результатам.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" md={6} sm={12}>
                                <div className={stylesAbout.textWrapper}>
                                    <div className={stylesAbout.headline}>Мотивация и интерес</div>
                                    <div className={stylesAbout.desc}>
                                        Фитнес-тренер – это человек, который умеет побеждать. Он уже прошел все этапы,
                                        справился с самыми распространенными проблемами, – болью в мышцах, эффектом
                                        плато,
                                        падением мотивации и т.д. – поэтому он как никто другой, сможет правильно Вас
                                        настроить и подбодрить, когда Вам покажется, что сил не осталось.<br/>
                                        <br/>
                                        Благодаря постоянному мониторингу тенденций отрасли, наставник всегда в курсе
                                        инновационных тренировок. Это позволяет разнообразить занятия и делать их более
                                        эффективными.
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            {/*<section id="slider" className={stylesService.slider}>
                <div className="container container__fluid-mobile">
                    <UiImageSlider slides={slides} color="primary"/>
                </div>
            </section>*/}
            {/*<section id="reviews" className={stylesService.reviews}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Отзывы</h4>
                    </div>
                    <Reviews/>
                </div>
            </section>*/}
            <section id="services" className={stylesService.services}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Другие услуги</h4>
                    </div>
                    <Services min={true} visibleIds={['gym', 'personal-training', 'mini-groups']}/>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
