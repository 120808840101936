import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';

import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {Swiper as SwiperClass} from 'swiper/types';

import './UiImageSlider.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ArrowForward} from '@mui/icons-material';
import {IconButton} from '@mui/material';

import styles from './UiImageSlider.module.scss';

type Props = {
    slides: Array<{
        img: string;
        name?: string;
    }>;
    color?: 'primary' | 'secondary';
};

export default function UiImageSlider({slides, color = 'primary'}: Props) {

    const [swiper, setSwiper] = useState<SwiperClass>();

    return (
        <div className="image-slider">
            <div className={styles.navigation}>
                <IconButton component="span" onClick={() => swiper?.slidePrev()}>
                    <ArrowBackIcon color={color} fontSize="large"/>
                </IconButton>
                <IconButton component="span" onClick={() => swiper?.slideNext()}>
                    <ArrowForward color={color} fontSize="large"/>
                </IconButton>
            </div>
            <Swiper
                slidesPerView={isMobile ? 1 : 1}
                centeredSlides={true}
                spaceBetween={isMobile ? 0 : 1}
                grabCursor={true}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                onSwiper={(swiper) => setSwiper(swiper)}
                onSlideChange={() => {
                }}
            >
                {slides.map((slide) => (
                    <SwiperSlide key={slide.img}>
                        <div className="slide-image" style={{backgroundImage: `url("${slide.img}")`}}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
