import React from 'react';

import {Button} from '@mui/material';

import {history} from '../../system/navigation/navigationUtils';

import styles from './NotFoundPage.module.scss';

export default function NotFoundPage() {
    return (
        <div>
            <div className={styles.notFound}>
                <div className={styles.squad}>
                    <div className={styles.code}>404</div>
                    <div className={styles.title}>Страница не найдена</div>
                    <Button
                        style={{fontWeight: 600}}
                        variant="contained"
                        onClick={() => history.push('/')}
                    >
                        Перейти на главную
                    </Button>
                </div>
            </div>
            <div className="footer-line-primary"/>
        </div>
    );
}
