import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';

import Banners from '../../components/blocks/Banners/Banners';
import {ContactsForm} from '../../components/blocks/ContactsForm/ContactsForm';
import Gallery from '../../components/blocks/Gallery/Gellery';
import Info from '../../components/blocks/Info/Info';
import PriceList from '../../components/blocks/PriceList/PriceList';
import Schedule from '../../components/blocks/Schedule/Schedule';
import ScheduleDesktop from '../../components/blocks/ScheduleDesktop/ScheduleDesktop';
import Services from '../../components/blocks/Services/Services';
import Team from '../../components/blocks/Team/Team';
import {mainGalleryImages} from '../../constants/images';

import styles from './MainPage.module.scss';

export default function MainPage() {
    return (
        <div>
            <section id="banners" className={`container ${styles.banners}`}>
                <Banners/>
            </section>
            <section id="info" className={styles.info}>
                <h2 className={`section-title ${styles.infoTitle}`}>Titan Gym это...</h2>
                <Info/>
            </section>
            <div className={`section-divider-wrap ${styles.servicesDivider}`}>
                <div className="section-divider-primary"/>
                <div className="section-divider-gray"/>
            </div>
            <section id="services" className={styles.services}>
                <div className="container">
                    <h3 className={`section-title ${styles.servicesTitle}`}>Услуги</h3>
                    <Services/>
                </div>
            </section>
            <div className="section-divider-wrap">
                <div className="section-divider-primary"/>
                <div className="section-divider-gray"/>
            </div>
            <section id="team" className={styles.team}>
                <div className="container">
                    <h3 className={`section-title ${styles.teamTitle}`}>Команда</h3>
                    <Team/>
                </div>
            </section>
            {/*<div className="section-divider-wrap">
                <div className="section-divider-primary"/>
                <div className="section-divider-gray"/>
            </div>*/}
            <section id="price" className={styles.price}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h3 className="section-title section-title__primary">Прайс-лист</h3>
                    </div>
                    <PriceList min={isMobile} all={false}/>
                </div>
            </section>
            {/*<section id="contactsForm" className={styles.contactsForm}>
                <div className="container">
                    <div className={styles.contactsFormWrap}>
                        <div className="section-title-wrap" style={{height: 100, minHeight: 100, paddingTop: 15}}>
                            <h3 className={`section-title ${styles.contactsFormTitle}`}>Получить скидку</h3>
                        </div>
                        <ContactsForm/>
                    </div>
                </div>
            </section>*/}
            <section id="schedule" className={styles.schedule}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h3 className={`section-title ${styles.scheduleTitle}`}>Расписание</h3>
                    </div>
                    <Schedule/>
                    <ScheduleDesktop/>
                </div>
            </section>
            {/*<div className="section-divider-wrap">
                <div className="section-divider-primary"/>
                <div className="section-divider-gray"/>
            </div>*/}
            {/*<section id="reviews" className={styles.reviews}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h4 className="section-title">Отзывы</h4>
                    </div>
                    <Reviews/>
                </div>
            </section>*/}
            <section id="gallery" className={`container ${styles.gallery}`}>
                <div className="section-title-wrap">
                    <h4 className="section-title">Мы в действии</h4>
                </div>
                <Gallery images={mainGalleryImages} cols={3}/>
            </section>
            {/*<section id="location" className={styles.location}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h3 className="section-title section-title__primary">
                            Ждем вас в зале
                        </h3>
                    </div>
                </div>
                <img className={styles.locationEnterImg} src="/img/location_enter.png" alt=""/>
            </section>*/}
            <div className="footer-line-primary footer-line-primary__desktop"/>
        </div>
    );
}
