import React from 'react';

import gym12Jpg from '../../../assets/img/info/gym12.jpg';
import gym32Jpg from '../../../assets/img/info/gym32.jpg';
import style3Jpg from '../../../assets/img/info/style3.jpg';

import styles from './Info.module.scss';


export default function Info() {
    return (
        <div className={`container ${styles.infoList}`}>
            <div className={styles.item}>
                <div className={styles.card} style={{marginRight: 26, marginLeft: 84}}>
                    <h5 className={styles.textMain}>Сильная<br/> атмосфера</h5>
                    <div className={styles.line}/>
                    <p className={styles.desc}>
                        Вас ждут новые высоты и скорый прогресс!<br/>
                        <br/>
                        Агрессивный интерьер и брутальные веса откроют второе
                        дыхание, даже когда покажется, что силы на исходе.
                    </p>
                </div>
                <div className={styles.image}>
                    <img className={styles.img} src={gym32Jpg} alt=""/>
                    <div className={styles.cardMini}>
                        <div className={styles.textMini}>ЦЕЛЬ</div>
                        <p className={styles.descMini}>атмосфера нашего зала точно не даст вам сдаться</p>
                    </div>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.image}>
                    <div className={styles.cardMini} style={{top: 70, left: -110, marginRight: -170}}>
                        <div className={styles.textMini}>2000+</div>
                        <p className={styles.descMini}>килограмм свободных дисков и грифов</p>
                    </div>
                    <img className={styles.img} src={style3Jpg} alt=""/>
                </div>
                <div className={styles.card} style={{marginRight: -40, marginLeft: 26}}>
                    <h5 className={styles.textMain}>Свободные<br/> веса</h5>
                    <div className={styles.line}/>
                    <p className={styles.desc}>
                        Единственный зал в Белгороде с более чем 2 000 кг свободных дисков!<br/>
                        <br/>
                        Большое количество грифов, вы никогда не будете в ожидании и поиске свободных весов.
                    </p>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.card} style={{marginRight: 26, marginLeft: 84}}>
                    <h5 className={styles.textMain}>Режим<br/> работы</h5>
                    <div className={styles.line}/>
                    <p className={styles.desc}>
                        В будние дни мы работаем с 8:00 и до 00:00!<br/>
                        <br/>
                        В субботу с 8:30 до 23:00, воскресенье с 10:00 до 23:00.
                    </p>
                </div>
                <div className={styles.image}>
                    <img className={styles.img} src={gym12Jpg} alt=""/>
                    <div className={styles.cardMini}>
                        <div className={styles.textMini}>00:00</div>
                        <p className={styles.descMini}>работаем до полуночи</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
