import React from 'react';

function nest(children: React.ReactNode, component: React.ReactElement) {
    return React.cloneElement(component, {}, children);
}

export type MultiProviderProps = React.PropsWithChildren<{
    providers: React.ReactElement[]
}>;

export default function MultiProvider({children, providers}: MultiProviderProps) {
    return (
        <>
            {providers.reduceRight(nest, children)}
        </>
    );
}
