import React from 'react';

import {Button, Grid} from '@mui/material';

import {history} from '../../../system/navigation/navigationUtils';

import styles from './PriceList.module.scss';

interface Props {
    min?: boolean;
    all?: boolean;
}

export default function PriceList({min = false, all = false}: Props) {
    return (
        <div>
            <div className={styles.priceList}>
                <h4 className="section-subtitle section-subtitle__primary">Тренажерный зал</h4>
                <Grid container>
                    <Grid item width="100%" md={6} sm={12}>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Разовое посещение</div>
                            <div className={styles.priceValue}>250 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Дневной (8:00 - 16:00)</div>
                            <div className={styles.priceValue}>900 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Студенческий безлимит</div>
                            <div className={styles.priceValue}>1400 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>TITAN LADY</div>
                            <div className={styles.priceValue}>3400 ₽</div>
                        </div>
                    </Grid>
                    <Grid item width="100%" md={6} sm={12}>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Безлимит на 1 месяц</div>
                            <div className={styles.priceValue}>1800 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Безлимит на 3 месяца</div>
                            <div className={styles.priceValue}>3800 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Безлимит на 6 месяцев</div>
                            <div className={styles.priceValue}>6900 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Безлимит на 1 год</div>
                            <div className={styles.priceValue}>11 900 ₽</div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item width="100%" md={6} sm={12}>
                        <h4 className="section-subtitle section-subtitle__primary">Персональные тренировки</h4>
                        <div className={styles.priceRowItems}>
                            <div className={styles.priceCol1}>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>1 тренировка</div>
                                    <div className={styles.priceValue}>1100 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>5 тренировок</div>
                                    <div className={styles.priceValue}>5250 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>10 тренировок</div>
                                    <div className={styles.priceValue}>10 000 ₽</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    {!min && (
                        <>
                            <Grid item width="100%" md={6} sm={12}>
                                <h4 className="section-subtitle section-subtitle__primary">Сплит тренировки</h4>
                                <div className={styles.priceRowItems}>
                                    <div className={styles.priceRow}>
                                        <div className={styles.priceName}>1 тренировка</div>
                                        <div className={styles.priceValue}>1300 ₽</div>
                                    </div>
                                    <div className={styles.priceRow}>
                                        <div className={styles.priceName}>5 тренировок</div>
                                        <div className={styles.priceValue}>6000 ₽</div>
                                    </div>
                                    <div className={styles.priceRow}>
                                        <div className={styles.priceName}>10 тренировок</div>
                                        <div className={styles.priceValue}>11 000 ₽</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width="100%" md={6} sm={12}>
                                <h4 className="section-subtitle section-subtitle__primary">Мини-группы</h4>
                                <div className={styles.priceRowItems}>
                                    <div className={styles.priceCol1}>
                                        <div className={styles.priceRow}>
                                            <div className={styles.priceName}>1 тренировка</div>
                                            <div className={styles.priceValue}>от 450 ₽</div>
                                        </div>
                                        <div className={styles.priceRow}>
                                            <div className={styles.priceName}>5 тренировок</div>
                                            <div className={styles.priceValue}>от 1800 ₽</div>
                                        </div>
                                        <div className={styles.priceRow}>
                                            <div className={styles.priceName}>8 тренировок</div>
                                            <div className={styles.priceValue}>от 2600 ₽</div>
                                        </div>
                                        <div className={styles.priceRow}>
                                            <div className={styles.priceName}>12 тренировок</div>
                                            <div className={styles.priceValue}>от 3600 ₽</div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </>
                    )}
                    <Grid item width="100%" md={6} sm={12}>
                        <h4 className="section-subtitle section-subtitle__primary">Групповые программы</h4>
                        <div className={styles.priceRowItems}>
                            <div className={styles.priceCol1}>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>1 тренировка</div>
                                    <div className={styles.priceValue}>350 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>8 тренировок</div>
                                    <div className={styles.priceValue}>1900 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>12 тренировок</div>
                                    <div className={styles.priceValue}>2400 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>36 тренировок</div>
                                    <div className={styles.priceValue}>5400 ₽</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    {!all && (
                        <Button
                            className={styles.btnMore}
                            variant="outlined"
                            onClick={() => history.push('/price')}
                        >
                            Подробнее
                        </Button>
                    )}
                </Grid>
            </div>

            {/*{all && (
                <div className={styles.priceListAlt}>
                    <Grid container spacing={isMobile ? 0 : 4}>
                        <Grid item width="100%" md={6} sm={12}>
                            <div className={styles.gridItemPrimary}>
                                <h4 className={`section-subtitle ${styles.subtitleStudent}`}>
                                    Абонементы для студентов
                                </h4>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Безлимит на 1 месяц</div>
                                    <div className={styles.priceValue}>1400 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Безлимит на 3 месяца</div>
                                    <div className={styles.priceValue}>2800 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Безлимит на 6 месяцев</div>
                                    <div className={styles.priceValue}>5200 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Безлимит на 1 год</div>
                                    <div className={styles.priceValue}>8900 ₽</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item width="100%" md={6} sm={12}>
                            <div className={styles.gridItemWhite}>
                                <h4 className="section-subtitle" style={{marginBottom: 0}}>
                                    Дневные абонементы
                                </h4>
                                <div className={styles.subtitleInfo}>С открытия и до 16:00</div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Абонемент на 1 месяц</div>
                                    <div className={styles.priceValue}>900 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Абонемент на 2 месяца</div>
                                    <div className={styles.priceValue}>1400 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Абонемент на 3 месяца</div>
                                    <div className={styles.priceValue}>2100 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Абонемент на 6 месяцев</div>
                                    <div className={styles.priceValue}>3900 ₽</div>
                                </div>
                                <div className={styles.priceRow}>
                                    <div className={styles.priceName}>Абонемент на 1 год</div>
                                    <div className={styles.priceValue}>7200 ₽</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
            {all && (
                <div className={styles.priceList} style={{marginTop: 35}}>
                    <h4 className="section-subtitle section-subtitle__primary">Массаж</h4>
                    <div className={styles.messageRowsList}>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Общий массаж</div>
                            <div className={styles.priceValue}>1600 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Спортивный массаж</div>
                            <div className={styles.priceValue}>1400 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Спортивный массаж (спина и руки/ноги)</div>
                            <div className={styles.priceValue}>1000 ₽</div>
                        </div>
                        <div className="divider-primary"/>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Массаж спины</div>
                            <div className={styles.priceValue}>800 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Массаж шейно-воротниковой зоны</div>
                            <div className={styles.priceValue}>500 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Массаж головы и стоп</div>
                            <div className={styles.priceValue}>400 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Лимфодренажный массаж</div>
                            <div className={styles.priceValue}>1300 ₽</div>
                        </div>
                        <div className="divider-primary"/>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Обёртывание (горячее, холодное, медовое)</div>
                            <div className={styles.priceValue}>300 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>SPA-массаж медовый</div>
                            <div className={styles.priceValue}>1500 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Балийский массаж</div>
                            <div className={styles.priceValue}>1500 ₽</div>
                        </div>
                        <div className="divider-primary"/>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Антицеллюлитный массаж</div>
                            <div className={styles.priceValue}>от 700 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Аппаратный массаж (перкуссионный, EMS)</div>
                            <div className={styles.priceValue}>от 700 ₽</div>
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceName}>Баночная процедура</div>
                            <div className={styles.priceValue}>от 700 ₽</div>
                        </div>
                    </div>
                </div>
            )}*/}
        </div>
    );
}
