import React from 'react';
import {Helmet} from 'react-helmet';

import PriceList from '../../components/blocks/PriceList/PriceList';

import styles from './PricePage.module.scss';

export default function PricePage() {
    return (
        <div>
            <Helmet>
                <title>Цены на услуги тренажерного зала Titan Gym Белгород</title>
            </Helmet>
            <section className={styles.price}>
                <div className="container">
                    <div className="section-title-wrap">
                        <h3 className="section-title section-title__primary">Прайс-лист</h3>
                    </div>
                    <PriceList min={false} all={true}/>
                </div>
            </section>
            <div className="footer-line-primary"/>
        </div>
    );
}
