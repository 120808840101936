import React, {useState} from 'react';

import {days, events} from '../../../constants/schedule';

import styles from './Schedule.module.scss';

type Props = {
    colorText?: string;
    background?: string;
};

export default function Schedule({colorText = 'white', background = 'black'}: Props) {

    const [activeDay, setActiveDay] = useState<number>(0);

    return (
        <div className={styles.scheduleMobile}>
            <div className={styles.daysList}>
                {days.map((day, index) => (
                    <div
                        className={styles.day}
                        style={{
                            background: activeDay === index ? '#f9e91a' : background,
                            color: activeDay === index ? 'black' : colorText,
                        }}
                        key={day}
                        onClick={() => setActiveDay(index)}
                    >
                        {day}
                    </div>
                ))}
            </div>
            <div className={styles.eventList}>
                {events[activeDay].length === 0 && (
                    <div className={styles.notFound}>В этот день нет групповых занятий</div>
                )}
                {events[activeDay].map((event, index) => (
                    <div className={styles.eventCard} style={{background: event.background}} key={index}>
                        <div className={styles.name}>{event.name}</div>
                        <div className={styles.time}>{event.time}</div>
                        <div className={styles.trainer}>{event.trainer}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
